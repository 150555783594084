import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { components } from 'react-select';
// styles
import classes from './Select.module.css';

const ControlComponent = (props) => {
  return <components.Control className={classNames(classes.CustomSelect, props.selectProps.fullWidth ? classes.FullWidth : null)} {...props} />
};

const CustomOption = (props) => {
  return <components.Option className={classes.CustomOption} {...props} />
}

const Menu = (props) => {
  return (
    <components.Menu {...props} className={classes.CustomMenu}>
      {props.children}
    </components.Menu>
  );
};

class CustomSelect extends React.Component {
  render() {
    return (
      <div className={classNames(classes.Wrapper, this.props.fullWidth ? classes.FullWidthWrapper : null)}>
        <Select
          components={{ Control: ControlComponent, Option: CustomOption, Menu: Menu }}
          options={this.props.options}
          placeholder={this.props.placeholder}
          value={{ value: this.props.value, label: this.props.label }}
          isSearchable={true}
          onChange={this.props.onChange}
          fullWidth={this.props.fullWidth} />
      </div>
    );
  }
}

CustomSelect.propTypes = {
  value: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
};

export default CustomSelect;