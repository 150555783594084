import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { editBusiness, createBusiness, setToast } from '../../../store/actions';
import * as AWS from '../../../aws';
import spinnerImg from '../../../assets/images/spinner.gif';
import placeholderImg from '../../../assets/images/placeholder-img.jpg';
// components
import GridItem from '../../../components/UI/Grid/GridItem';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import CardHeader from '../../../components/UI/Card/CardHeader';
import Button from '../../../components/UI/CustomButtons/Button';
// material ui components
import { TextField } from '@material-ui/core';
// styles
import classes from './BusinessForm.module.css';

const BusinessForm = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [logoImg, setLogoImg] = useState(null);

  const { businessId } = params;
  const { editMode, business } = location.state;

  useEffect(() => {
    const fetchLogoImg = async () => {
      if (editMode && business.logo) {
        const data = await AWS.getObject(business.logo.slice(1));
        setLogoImg(data);
      }
    };
    fetchLogoImg();
    /*eslint-disable-next-line*/
  }, []);

  const formik = useFormik({
    isInitialValid: editMode,
    initialValues: {
      logo: null,
      tradeName: editMode ? business.tradeName : '',
      name: editMode ? business.name : '',
      RUT: editMode ? business.RUT : '',
      contactEmail: editMode ? business.contactEmail : '',
      webUrl: editMode ? business.webUrl : '',
      address: editMode ? business.address : '',
      phone: editMode ? business.phone : '',
      onboardingTextSup: editMode ? business.onboardingTextSup : '',
      onboardingTextInf: editMode ? business.onboardingTextInf : '',
    },
    validationSchema: yup.object().shape({
      tradeName: yup.string().required('Requerido'),
      name: yup.string().required('Requerido'),
      RUT: yup.string().required('Requerido'),
      contactEmail: yup
        .string()
        .required('Requerido')
        .email('Ingrese un email válido'),
      webUrl: yup
        .string()
        .required('Requerido')
        .url('Ingrese un sitio web válido'),
      address: yup.string().required('Requerido'),
      phone: yup.string().required('Requerido'),
    }),
    onSubmit: async (values) => {
      try {
        if (values.logo) {
          // If we have a previous logo, delete it
          if (editMode && business.logo) {
            await AWS.deleteObject(business.logo.slice(1));
          }

          // Upload new logo
          const key = `/logosempresas/${values.logo.name}`;
          await AWS.uploadObject(values.logo, key.slice(1));
          values.logo = key;
        }

        if (editMode) {
          await dispatch(editBusiness(businessId, values));
          dispatch(setToast('La entidad fue editada con éxito'));
        } else {
          await dispatch(createBusiness(values));
          dispatch(setToast('La entidad fue creada con éxito'));
        }

        history.push('/businesses');
      } catch (err) {
        if (editMode) {
          dispatch(
            setToast('Se produjo un error al editar la entidad', 'danger'),
          );
        } else {
          dispatch(
            setToast('Se produjo un error al crear la entidad', 'danger'),
          );
        }
      }
    },
  });

  const hasError = (name) => !!formik.errors[name] && formik.touched[name];
  let logoImgUrl = null;
  if (formik.values.logo) {
    logoImgUrl = URL.createObjectURL(formik.values.logo);
  } else if (editMode && business.logo) {
    logoImgUrl = logoImg;
  } else {
    logoImgUrl = placeholderImg;
  }

  return (
    <Card profile>
      <CardHeader>
        <h1 className={classes.Title}>
          {editMode ? 'Editar Entidad' : 'Crear Entidad'}
        </h1>
      </CardHeader>
      <CardBody profile>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer>
            <GridItem container alignItems="center" xs={12} sm={12} md={6}>
              <img
                src={logoImgUrl || spinnerImg}
                style={{ maxWidth: 50, marginRight: '1rem' }}
                alt="Logo de la entidad"
              />
              <TextField
                style={{ flex: 1 }}
                name="logo"
                type="file"
                margin="dense"
                variant="outlined"
                label="Logo"
                onChange={(event) =>
                  formik.setFieldValue(event.target.name, event.target.files[0])
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: 'image/jpeg,image/png' }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="tradeName"
                type="text"
                margin="dense"
                variant="outlined"
                label="Razón Social"
                error={hasError('tradeName')}
                helperText={hasError('tradeName') && formik.errors.tradeName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tradeName}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="name"
                type="text"
                margin="dense"
                variant="outlined"
                label="Nombre Comercial"
                error={hasError('name')}
                helperText={hasError('name') && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="RUT"
                type="text"
                margin="dense"
                variant="outlined"
                label="RUT"
                error={hasError('RUT')}
                helperText={hasError('RUT') && formik.errors.RUT}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.RUT}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="contactEmail"
                type="email"
                margin="dense"
                variant="outlined"
                label="Email"
                error={hasError('contactEmail')}
                helperText={
                  hasError('contactEmail') && formik.errors.contactEmail
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contactEmail}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="webUrl"
                type="text"
                margin="dense"
                variant="outlined"
                label="Sitio web"
                error={hasError('webUrl')}
                helperText={hasError('webUrl') && formik.errors.webUrl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.webUrl}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="address"
                type="text"
                margin="dense"
                variant="outlined"
                label="Dirección"
                error={hasError('address')}
                helperText={hasError('address') && formik.errors.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="phone"
                type="text"
                margin="dense"
                variant="outlined"
                label="Teléfono"
                error={hasError('phone')}
                helperText={hasError('phone') && formik.errors.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                multiline
                rows={2}
                rowsMax={4}
                className={classes.Input}
                name="onboardingTextSup"
                type="text"
                margin="dense"
                variant="outlined"
                label="Texto Onboarding Superior"
                error={hasError('onboardingTextSup')}
                helperText={
                  hasError('onboardingTextSup') &&
                  formik.errors.onboardingTextSup
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.onboardingTextSup}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                multiline
                rows={2}
                rowsMax={4}
                className={classes.Input}
                name="onboardingTextInf"
                type="text"
                margin="dense"
                variant="outlined"
                label="Texto Onboarding Inferior"
                error={hasError('onboardingTextInf')}
                helperText={
                  hasError('onboardingTextInf') &&
                  formik.errors.onboardingTextInf
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.onboardingTextInf}
                required
              />
            </GridItem>
          </GridContainer>
        </form>
      </CardBody>
      <CardFooter>
        <Button
          className={classes.Button}
          color="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
        >
          {editMode ? 'ACTUALIZAR ENTIDAD' : 'CREAR ENTIDAD'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default BusinessForm;
