import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useClearCache } from 'react-clear-cache';
import * as actions from '../store/actions';
// components
import Spinner from '../components/UI/Spinner/Spinner';
// HOC
import Layout from '../hoc/Layout/Layout';
import FullScreenLayout from '../hoc/Layout/FullScreenLayout/FullScreenLayout';
// routes
import indexRoutes from '../routes/index';
import classes from './App.module.css';

const App = () => {
  useClearCache({ auto: true, basePath: process.env.PUBLIC_URL });

  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(actions.authCheckState());
  }, [dispatch]);

  if (isLoggedIn === null) {
    return (
      <div className={classes.SpinnerContainer}>
        <Spinner />
      </div>
    );
  }

  const onSettings = () => {
    history.push({
      pathname: '/admin/settings',
      state: {
        editMode: true,
      },
    });
  };

  return (
    <Switch>
      <Route path="/login" component={FullScreenLayout} />
      {isLoggedIn ? (
        <Layout onLogout={() => dispatch(actions.logout())} onSettings={onSettings}>
          <Switch>
            {indexRoutes.map((route, key) => {
              if (route.onlySuperAdmin && !user.isSuperAdmin) {
                return null;
              }
              return <Route exact path={route.path} component={route.component} key={key} />;
            })}
            {!user?.isSuperAdmin && <Redirect to="/businesses" />}
          </Switch>
        </Layout>
      ) : (
        <Redirect to="/login" />
      )}
    </Switch>
  );
};

export default App;
