import React, { useState } from 'react';
import { queryCache, useQuery, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { setToast } from '../../store/actions';
import moment from 'moment';
import axios from '../../axios';
// components
import TableList from '../../components/UI/TableList/TableList';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import Input from '../../components/UI/Input/Input';
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
// material ui components
import { withStyles } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
// styles
import listsContainerStyles from '../../assets/jss/containers/listsContainer';
import classes from './Requests.module.css';

const REQUEST_STATUS = {
  PENDING: 'Pendiente',
  ACCEPTED: 'Aceptada',
  REJECTED: 'Rechazada',
};

const PAGE_LIMIT = 12;

const todayDate = moment().format('DD-MM-YYYY');

const fetchRequests = () => {
  return axios.get('/requests').then((res) => res.data.data);
};

const fetchBusinesses = () => {
  return axios.get('/businesses').then((res) =>
    res.data.data.map((business) => {
      return {
        value: business.businessId,
        label: business.name,
      };
    }),
  );
};

const updateRequestStatus = ({ requestId, status }) => {
  return axios
    .patch(`/requests/${requestId}/status`, { status })
    .then((res) => res.data.data);
};

const Requests = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState('Todas');
  const [selectedStatus, setSelectedStatus] = useState('Todos');
  const [userSearch, setUserSearch] = useState('');

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const requestsQuery = useQuery('requests', fetchRequests);
  const businessesQuery = useQuery('businesses', fetchBusinesses);
  const [updateStatus] = useMutation(updateRequestStatus, {
    onSuccess: () => {
      queryCache.setQueryData('requests', (oldRequests) => {
        const newRequests = [...oldRequests];
        const requestIndex = newRequests.findIndex(
          (request) => request.requestId === selectedRequest.id,
        );
        newRequests[requestIndex].status = selectedRequest.newStatus;
        return newRequests;
      });
      dispatch(setToast('La solicitud fue modificada con éxito'));
    },
    onError: () => {
      dispatch(
        setToast('Se produjo un error al modificar la solicitud', 'danger'),
      );
    },
  });

  if (requestsQuery.isLoading) {
    return <Spinner />;
  }

  const changeRequestStatus = (id, newStatus) => {
    setShowDialog(true);
    setSelectedRequest({ id, newStatus });
  };

  const updateStatusHandler = () => {
    updateStatus({
      requestId: selectedRequest.id,
      status: selectedRequest.newStatus,
    });
    setShowDialog(false);
    setSelectedRequest(null);
  };

  const closeDialogHandler = () => {
    setSelectedRequest(null);
    setShowDialog(false);
  };

  let filteredRequests = requestsQuery.data;

  if (userSearch) {
    filteredRequests = filteredRequests.filter((request) => {
      return ['firstName', 'lastName'].some((key) => {
        return request.user[key]
          .toLowerCase()
          .includes(userSearch.toLowerCase());
      });
    });
  }

  if (selectedBusiness !== 'Todas') {
    filteredRequests = filteredRequests.filter((request) => {
      return request.user.businessId === selectedBusiness;
    });
  }

  if (selectedStatus !== 'Todos') {
    filteredRequests = filteredRequests.filter((request) => {
      return request.status === selectedStatus;
    });
  }

  const pendingRequests = filteredRequests
    .filter((request) => request.status === 'PENDING')
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const notPendingRequests = filteredRequests.filter(
    (request) => request.status !== 'PENDING',
  );

  const requests = pendingRequests.concat(notPendingRequests).map((request) => {
    let status = REQUEST_STATUS[request.status];
    if (!request.user.active) {
      status += ' - Usuario Eliminado';
    }
    return [
      request.requestId,
      request.requestId,
      `${request.user.firstName} ${request.user.lastName}`,
      request.user.business?.name,
      request.user.bank.name,
      request.user.accountNumber,
      request.amount,
      request.rateFinal,
      moment(request.createdAt).format('DD/MM/YYYY HH:mm'),
      status,
    ];
  });

  const tableData = requests.slice(
    (currentPage - 1) * PAGE_LIMIT,
    currentPage * PAGE_LIMIT,
  );

  const tableHead = [
    'Id',
    'ID',
    'Usuario',
    'Empresa',
    'Banco',
    'Cuenta',
    'Monto',
    'Tarifa',
    'Fecha solicitud',
    'Estado',
    '',
  ];

  const filters = (
    <GridContainer className={classes.Search}>
      <GridItem xs={12} sm={12} md={3}>
        <Input
          element={{
            label: 'Buscar por usuario..',
            elementType: 'input',
            elementConfig: {
              type: 'text',
              placeholder: 'Buscar por usuario..',
            },
            value: userSearch,
            validation: {},
          }}
          onChange={(value) => setUserSearch(value)}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <Input
          element={{
            label: 'Entidad',
            elementType: 'select',
            elementConfig: {
              variant: 'outlined',
              options: [
                { value: 'Todas', label: 'Todas' },
                ...(businessesQuery.data || []),
              ],
              mdUpSize: 6,
            },
            value: selectedBusiness,
          }}
          onChange={(value) => setSelectedBusiness(value)}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <Input
          element={{
            label: 'Estado',
            elementType: 'select',
            elementConfig: {
              variant: 'outlined',
              options: [
                { value: 'Todos', label: 'Todos' },
                { value: 'ACCEPTED', label: 'Aceptado' },
                { value: 'PENDING', label: 'Pendiente' },
                { value: 'REJECTED', label: 'Rechazado' },
              ],
              mdUpSize: 6,
            },
            value: selectedStatus,
          }}
          onChange={(value) => setSelectedStatus(value)}
        />
      </GridItem>
    </GridContainer>
  );

  const newStatus = selectedRequest
    ? REQUEST_STATUS[selectedRequest.newStatus].toLowerCase()
    : '';

  return (
    <React.Fragment>
      <TableList
        title="Solicitudes"
        head={tableHead}
        data={tableData}
        filters={filters}
        setStatus={true}
        onSetAsPaid={(requestId) => changeRequestStatus(requestId, 'ACCEPTED')}
        onSetAsRejected={(requestId) =>
          changeRequestStatus(requestId, 'REJECTED')
        }
        pagination={
          <Pagination
            totalRecords={filteredRequests.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={({ currentPage }) => setCurrentPage(currentPage)}
          />
        }
        headerActions={
          <div>
            <CSVLink
              enclosingCharacter=""
              separator=";"
              filename={`sueldoYa_solicitudes_${todayDate}.csv`}
              headers={tableHead.slice(1)}
              data={requests.map((request) => request.slice(1))}
              className={classes.Button}
            >
              <GetApp className={classes.ButtonIcon} />{' '}
              <span>Descargar Listado</span>
            </CSVLink>
          </div>
        }
      />
      <CustomDialog
        title={`Solicitud ${selectedRequest?.id}`}
        description={`¿Estás seguro que deseas marcar la solicitud como ${newStatus}?`}
        open={showDialog}
        onConfirmation={updateStatusHandler}
        onClose={closeDialogHandler}
        okButtonText="Confirmar"
        cancelButtonText="Cancelar"
      />
    </React.Fragment>
  );
};

export default withStyles(listsContainerStyles)(Requests);
