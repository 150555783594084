import sha256 from 'crypto-js/sha256';

import * as actionTypes from './actionTypes';
import axios from '../../axios';

const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

const authSuccess = (user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    user,
  };
};

const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};

export const adminUpdateSucessful = (admin) => (dispatch) => {
  dispatch({
    type: actionTypes.ADMIN_UPDATE_SUCCESSFUL,
    user: admin,
  });
}

export const login = (email, password) => async (dispatch) => {
  dispatch(authStart());
  try {
    const hashedPassword = sha256(password).toString();
    const { data } = await axios.post('/admins/login', {
      email,
      password: hashedPassword,
    });
    localStorage.setItem('token', data.data.token);
    localStorage.setItem('userId', data.data.admin.adminId);
    axios.defaults.headers.common.authorization = `Bearer ${data.data.token}`;
    dispatch(authSuccess(data.data.admin));
  } catch (err) {
    let errorMessage = 'Se produjo un error al iniciar sesión.';
    if (err.response?.data?.showMessage?.ES) {
      errorMessage = err.response.data.showMessage.ES;
    }
    dispatch(authFail(errorMessage));
  }
};

export const authCheckState = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  if (token) {
    const userId = localStorage.getItem('userId');
    axios.defaults.headers.common.authorization = `Bearer ${token}`;
    try {
      const { data } = await axios.get(`/admins/${userId}`);
      dispatch(authSuccess(data.data));
    } catch (error) {
      dispatch(authFail());
    }
  } else {
    dispatch(authFail());
  }
};

export const logout = () => async (dispatch) => {
  try {
    await axios.post('/admins/logout', {});
    dispatch(forceLogout());
  } catch (err) {
    console.log(err);
  }
};

export const forceLogout = () => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  delete axios.defaults.headers.common.authorization;
  dispatch(logoutSuccess());
};

const logoutSuccess = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};
