import * as actionTypes from '../actions/actionTypes';

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
      };
    case actionTypes.FETCH_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.user],
      };
    case actionTypes.EDIT_USER_SUCCESS:
      const userIndex = state.users.findIndex((u) => {
        return u.userId === action.user.userId;
      });
      const updatedUsers = [...state.users];
      updatedUsers[userIndex] = action.user;
      return {
        ...state,
        users: updatedUsers,
      };
    case actionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((u) => {
          return u.userId !== action.userId;
        }),
      };
    case actionTypes.SUSPEND_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          if (action.userId === user.userId) {
            return {
              ...user,
              suspendedAt: action.suspend ? new Date() : null,
            };
          }
          return user;
        }),
      };
    case actionTypes.UPDATE_USER_PROPS:
      return {
        ...state,
        users: state.users.map((user) => {
          if (action.payload.userIds.some((id) => id === user.userId)) {
            return {
              ...user,
              ...action.payload.payload,
            };
          }
          return user;
        }),
      };
    default:
      return state;
  }
};

export default usersReducer;
