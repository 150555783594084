import * as actionTypes from '../actions/actionTypes';

const initialState = {
  calendars: [],
};

const calendarsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CALENDARS:
      return {
        ...state,
        calendars: action.calendars,
      };
    case actionTypes.CREATE_CALENDAR:
      return {
        ...state,
        calendars: [...state.calendars, action.calendar],
      };
    case actionTypes.EDIT_CALENDAR:
      const calendarIndex = state.calendars.findIndex((c) => {
        return c.calendarTemplateId === action.calendar.calendarTemplateId;
      });
      const updatedCalendars = [...state.calendars];
      updatedCalendars[calendarIndex] = action.calendar;
      return {
        ...state,
        calendars: updatedCalendars,
      };
    case actionTypes.DELETE_CALENDAR:
      return {
        ...state,
        calendars: state.calendars.filter(
          (calendar) => calendar.calendarTemplateId !== action.payload,
        ),
      };
    default:
      return state;
  }
};

export default calendarsReducer;
