import * as actionTypes from './actionTypes';
import axios from '../../axios';
import { setToast } from './toast';

const createUserSuccess = (user) => {
  return {
    type: actionTypes.CREATE_USER_SUCCESS,
    user,
  };
};

export const createUser = (user) => async (dispatch) => {
  try {
    const { data } = await axios.post('/users', user);
    dispatch(createUserSuccess(data.data));
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const fetchUsersStart = () => {
  return {
    type: actionTypes.FETCH_USERS_START,
  };
};

const fetchUsersSuccess = (users) => {
  return {
    type: actionTypes.FETCH_USERS_SUCCESS,
    users,
  };
};

const fetchUsersFail = (error) => {
  return {
    type: actionTypes.FETCH_USERS_FAIL,
    error,
  };
};

export const fetchUsers = () => async (dispatch) => {
  dispatch(fetchUsersStart());
  try {
    const { data } = await axios.get('/users');
    dispatch(fetchUsersSuccess(data.data));
  } catch (err) {
    let errorMessage = 'Error al obtener el listado de usuarios';
    dispatch(fetchUsersFail(errorMessage));
    throw err;
  }
};

const editUserSuccess = (user) => {
  return {
    type: actionTypes.EDIT_USER_SUCCESS,
    user,
  };
};

export const editUser = (userId, userData) => async (dispatch) => {
  try {
    const { data } = await axios.patch(`/users/${userId}`, userData);
    dispatch(editUserSuccess(data.data));
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const deleteUserSuccess = (userId) => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    userId,
  };
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    await axios.delete(`/users/${userId}`);
    dispatch(deleteUserSuccess(userId));
  } catch (err) {
    if (err.response.data) {
      dispatch(setToast('Error al intentar eliminar el usuario', 'danger'));
    }
    throw err;
  }
};

const suspendUserSuccess = (userId, suspend) => {
  return {
    type: actionTypes.SUSPEND_USER_SUCCESS,
    userId,
    suspend,
  };
};

export const suspendUser = (userId, suspend = true) => async (dispatch) => {
  try {
    await axios.post(`/users/${userId}/suspend`, { suspend });
    dispatch(suspendUserSuccess(userId, suspend));
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const uploadUsers = (businessId, file) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('businessId', businessId);
    formData.append('file', file);
    await axios.post('/users/import', formData);
    dispatch(fetchUsers());
  } catch (err) {
    if (err.response.data) {
      dispatch(setToast('Parámetros inválidos en el archivo CSV', 'danger'));
    }
    throw err;
  }
};

export const modifyAccumValue = (userIds, accumType, accumValue) => async (
  dispatch,
) => {
  try {
    const body = {
      userIds,
      payload: {
        accumType,
        accumAmount: accumType === 'FIXED' ? accumValue : null,
        accumPercentage: accumType === 'PERCENTAGE' ? accumValue : null,
      },
    };
    await axios.patch('/users/batch/accums', body);
    dispatch({ type: actionTypes.UPDATE_USER_PROPS, payload: body });
  } catch (err) {
    throw err;
  }
};

export const modifyRateValues = (userIds, rateAmount, ratePercentage) => async (
  dispatch,
) => {
  try {
    const body = {
      userIds,
      payload: {
        rateAmount,
        ratePercentage,
      },
    };
    await axios.patch('/users/batch/rates', body);
    dispatch({ type: actionTypes.UPDATE_USER_PROPS, payload: body });
  } catch (err) {
    throw err;
  }
};

export const modifyDailyIncrementValue = (userIds, dailyIncrement) => async (
  dispatch,
) => {
  try {
    const body = {
      userIds,
      payload: {
        dailyIncrement,
      },
    };
    await axios.patch('/users/batch/daily-increment', body);
    dispatch({
      type: actionTypes.UPDATE_USER_PROPS,
      payload: body,
    });
  } catch (err) {
    throw err;
  }
};
