import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import sha256 from 'crypto-js/sha256';
import { useFormik } from 'formik';
import axios from '../../axios';
import * as yup from 'yup';


import { setToast } from '../../store/actions';

// components
import OutlinedSelect from '../../components/UI/Input/OutlinedSelect/OutlinedSelect';
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import Card from '../../components/UI/Card/Card';
import CardBody from '../../components/UI/Card/CardBody';
import CardFooter from '../../components/UI/Card/CardFooter';
import CardHeader from '../../components/UI/Card/CardHeader';
import Button from '../../components/UI/CustomButtons/Button';
// material ui components
import { TextField, Chip, Paper, Switch, FormControlLabel } from '@material-ui/core';
//styles
import classes from '../Administrators/AdministratorForm/AdministratorForm.module.css';

import { adminUpdateSucessful } from '../../store/actions/auth';

const updateAdminMutation = ({ adminId, ...values }) => {
    return axios.patch(`/admins/${adminId}`, values).then((res) => res.data.data);
  };

export default () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [updateAdmin] = useMutation(updateAdminMutation);
    const { user: admin } = useSelector(state => state.auth);
    const [showChangePassword, setShowChangePassword] = useState(false);

    const { editMode } = location.state;

    const formik = useFormik({
        isInitialValid: editMode,
        initialValues: {
            firstName: editMode ? admin.firstName : '',
            lastName: editMode ? admin.lastName : '',
            email: editMode ? admin.email : '',
            password: '',
            passwordConfirm: '',
        },
        validationSchema: yup.object().shape({
            firstName: yup.string().required('Requerido'),
            lastName: yup.string().required('Requerido'),
            email: yup
                .string()
                .required('Requerido')
                .email('Ingrese un email válido'),
            password: yup
                .string()
                .optional()
                .min(8, 'Debe tener al menos 8 caractéres'),
            passwordConfirm: yup
                .string()
                .oneOf([yup.ref('password'), null], "Las contraseñas no son iguales")

        }),
        onSubmit: async (values) => {
            const body = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
            };
            if (values.password) {
                body.password = sha256(values.password).toString();
            }
            try {
                const { adminId } = admin;
                const updatedAdmin = await updateAdmin({ adminId, ...body });
                dispatch(setToast('El administrador fue editado con éxito'));
                dispatch(adminUpdateSucessful(updatedAdmin));
            } catch (error) {
                dispatch(setToast('Se produjo un error al editar el administrador', 'danger'));
            }
        },
    });

    const hasError = (name) => !!formik.errors[name] && formik.touched[name];

    return (
        <Card profile>
            <CardHeader>
                <h1 className={classes.Title}>
                    Configuración Administrador
                </h1>
            </CardHeader>
            <CardBody profile>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <TextField
                                className={classes.Input}
                                name="firstName"
                                type="text"
                                margin="dense"
                                variant="outlined"
                                label="Nombre"
                                error={hasError('firstName')}
                                helperText={hasError('firstName') && formik.errors.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                                required
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <TextField
                                className={classes.Input}
                                name="lastName"
                                type="text"
                                margin="dense"
                                variant="outlined"
                                label="Apellido"
                                error={hasError('lastName')}
                                helperText={hasError('lastName') && formik.errors.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                                required
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <TextField
                                className={classes.Input}
                                name="email"
                                type="email"
                                margin="dense"
                                variant="outlined"
                                label="Email"
                                error={hasError('email')}
                                helperText={hasError('email') && formik.errors.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                required
                            />
                        </GridItem>
                        <GridItem md={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={() => setShowChangePassword(!showChangePassword)}
                                        name="password-modifier"
                                        color="primary"
                                    />
                                }
                                label="Modificar contraseña"
                            />
                        </GridItem>
                        {
                            showChangePassword &&
                            (<GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    className={classes.Input}
                                    name="password"
                                    type="password"
                                    margin="dense"
                                    variant="outlined"
                                    label="Nueva Contraseña"
                                    error={hasError('password')}
                                    helperText={hasError('password') && formik.errors.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    required={!editMode}
                                />
                            </GridItem>)
                        }
                        {
                            showChangePassword &&
                            (<GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    className={classes.Input}
                                    name="passwordConfirm"
                                    type="password"
                                    margin="dense"
                                    variant="outlined"
                                    label="Confirmar Nueva Contraseña"
                                    error={hasError('passwordConfirm')}
                                    helperText={hasError('passwordConfirm') && formik.errors.passwordConfirm}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.passwordConfirm}
                                    required={!editMode}
                                />
                            </GridItem>)
                        }
                    </GridContainer>
                </form>
            </CardBody>
            <CardFooter>
                <Button
                    className={classes.Button}
                    color="primary"
                    onClick={formik.handleSubmit}
                    disabled={!formik.isValid}
                >
                    Actualizar
          </Button>
            </CardFooter>
        </Card>
    );
};
