import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import axios from '../axios';
// components
import GridContainer from './UI/Grid/GridContainer';
import GridItem from './UI/Grid/GridItem';
import CustomDialog from './UI/CustomDialog/CustomDialog';
import Input from './UI/Input/Input';

const editMassiveDaysMutation = (params) => {
  return axios
    .patch(`/calendar-templates/batch/days`, params)
    .then((res) => res.data.data);
};

const MonthDetailMassiveForm = ({
  selectedUsers,
  monthDetail,
  open,
  onCloseDialog,
  days,
  onMutationSuccess,
}) => {
  const [mutate] = useMutation(editMassiveDaysMutation, {
    onSuccess: async () => {
      await onMutationSuccess();
      onCloseDialog();
    },
  });

  const formik = useFormik({
    initialValues: {
      isFrozen: false,
      isNotWorkday: false,
      isHoliday: false,
      dayNumber: 1,
    },
    onSubmit: (values) => {
      const dayIds = [];
      for (const userId of selectedUsers) {
        const user = monthDetail.find((u) => u.userId === userId);
        const userDays = user.months[0].days;
        dayIds.push(userDays[values.dayNumber - 1].dayId);
      }
      mutate({
        dayIds,
        payload: {
          isWorkday: !values.isNotWorkday,
          isFrozen: values.isFrozen,
          isHoliday: values.isHoliday,
        },
      });
    },
  });

  return (
    <CustomDialog
      title="Modificación masiva"
      description=""
      open={open}
      onConfirmation={formik.handleSubmit}
      onClose={onCloseDialog}
      okButtonText="Editar"
      cancelButtonText="Cancelar"
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <FormControlLabel
            label="Congelado"
            control={<Checkbox color="primary" />}
            checked={formik.values.isFrozen}
            onChange={(event) =>
              formik.setFieldValue('isFrozen', event.target.checked)
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormControlLabel
            label="Feriado"
            control={<Checkbox color="primary" />}
            checked={formik.values.isHoliday}
            onChange={(event) =>
              formik.setFieldValue('isHoliday', event.target.checked)
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormControlLabel
            label="No hábil"
            control={<Checkbox color="primary" />}
            checked={formik.values.isNotWorkday}
            onChange={(event) =>
              formik.setFieldValue('isNotWorkday', event.target.checked)
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Input
            element={{
              label: 'Día',
              elementType: 'select',
              elementConfig: {
                variant: 'outlined',
                options: days.map((day) => ({ value: day, label: day })),
                mdUpSize: 6,
              },
              value: formik.values.dayNumber,
            }}
            onChange={(value) => formik.setFieldValue('dayNumber', value)}
          />
        </GridItem>
      </GridContainer>
    </CustomDialog>
  );
};

export default MonthDetailMassiveForm;
