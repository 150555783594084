import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchBusinesses,
  deleteBusiness,
  editBusiness,
} from '../../store/actions';
// components
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import TableList from '../../components/UI/TableList/TableList';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
import Input from '../../components/UI/Input/Input';
// material ui core components
import { withStyles } from '@material-ui/core/styles';
// styles
import listsContainerStyles from '../../assets/jss/containers/listsContainer';
import classes from './Businesses.module.css';

const PAGE_LIMIT = 12;

const Businesses = () => {
  const { user } = useSelector((state) => state.auth);
  const [showDialog, setShowDialog] = useState(false);
  const [businessToDelete, setBusinessToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleted, setShowDeleted] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchBusinesses(!showDeleted));
  }, [dispatch, showDeleted]);

  const { businesses, loading } = useSelector((state) => state.businesses);

  const pageClickHandler = ({ currentPage }) => {
    setCurrentPage(currentPage);
  };

  const createBusinessHandler = () => {
    history.push({
      pathname: '/businesses/create',
      state: {
        editMode: false,
      },
    });
  };

  const editBusinessHandler = (businessId) => {
    const business = businesses.find((b) => b.businessId === businessId);
    history.push({
      pathname: '/businesses/' + businessId + '/edit',
      state: {
        business,
        editMode: true,
      },
    });
  };

  const deleteBusinessHandler = () => {
    if (businessToDelete.deleted) {
      dispatch(
        editBusiness(businessToDelete.businessId, {
          ...businessToDelete,
          deleted: false,
        }),
      );
    } else {
      dispatch(deleteBusiness(businessToDelete.businessId));
    }
    setBusinessToDelete(null);
    setShowDialog(false);
  };

  const openDialogHandler = (businessId) => {
    const business = businesses.find((b) => b.businessId === businessId);
    setBusinessToDelete(business);
    setShowDialog(true);
  };

  const closeDialogHandler = () => {
    setBusinessToDelete(null);
    setShowDialog(false);
  };

  let businessesList = null;
  if (loading) {
    businessesList = <Spinner />;
  } else if (businesses.length > 0) {
    businesses.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    const currentPageBusinesses = businesses.slice(
      (currentPage - 1) * PAGE_LIMIT,
      currentPage * PAGE_LIMIT,
    );

    const businessesData = currentPageBusinesses.map((business) => {
      return [
        business.businessId,
        business.tradeName,
        business.RUT,
        business.contactEmail,
        business.webUrl,
        business.address,
        business.phone,
        business.deleted ? 'Eliminado' : 'Activo',
      ];
    });

    const filters = (
      <GridContainer className={classes.Filter}>
        <GridItem xs={12} sm={12} md={3}>
          <Input
            element={{
              elementType: 'checkbox',
              elementConfig: {
                label: 'Incluir eliminadas',
                mdUpSize: 4,
              },
              value: showDeleted,
            }}
            onChange={(value) => setShowDeleted(value)}
          />
        </GridItem>
      </GridContainer>
    );

    businessesList = (
      <TableList
        title="Entidades"
        head={[
          'Id',
          'Razón Social',
          'RUT',
          'Email',
          'Web',
          'Dirección',
          'Teléfono',
          'Estado',
          '',
        ]}
        data={businessesData}
        onEdit={editBusinessHandler}
        onDisable={openDialogHandler}
        edit
        disableable
        filters={filters}
        highlightDeleted
        pagination={
          <Pagination
            totalRecords={businesses.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={pageClickHandler}
          />
        }
      />
    );
  }

  let dialogMessage = '';
  let dialogText = '';
  if (businessToDelete) {
    dialogText = businessToDelete.deleted ? 'activar' : 'eliminar';
    dialogMessage = `¿Estás seguro que deseas ${dialogText} la entidad ${businessToDelete.name}?`;
  }

  let AddBusinessButton;
  if (user?.isSuperAdmin) AddBusinessButton = <AddButton onClick={createBusinessHandler} />;

  return (
    <Fragment>
      {businessesList}
      { AddBusinessButton }
      <CustomDialog
        title="Confirmar"
        description={dialogMessage}
        open={showDialog}
        onConfirmation={deleteBusinessHandler}
        onClose={closeDialogHandler}
        okButtonText={dialogText}
        cancelButtonText="Cancelar"
      />
    </Fragment>
  );
};

export default withStyles(listsContainerStyles)(Businesses);
