import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
// components
import CustomDialog from '../UI/CustomDialog/CustomDialog';
// material ui components
import { TextField } from '@material-ui/core';

const ModifyDailyIncrementValuesForm = (props) => {
  const formik = useFormik({
    initialValues: {
      dailyIncrement: '',
    },
    validationSchema: yup.object().shape({
      dailyIncrement: yup
        .number()
        .required('Requerido')
        .min(0, 'El valor mínimo es 0'),
    }),
    onSubmit: (values) => {
      props.onSubmit(values);
    },
  });

  const hasError = (name) => !!formik.errors[name] && formik.touched[name];

  return (
    <CustomDialog
      title="Modificar Incremento Diario"
      description=""
      open={props.open}
      onConfirmation={formik.handleSubmit}
      onClose={props.onCloseDialog}
      okButtonText="Modificar"
      cancelButtonText="Cancelar"
    >
      <form onSubmit={formik.handleSubmit}>
        <TextField
          style={{ width: '100%' }}
          name="dailyIncrement"
          type="number"
          margin="dense"
          variant="outlined"
          label="Nuevo Incremento Diario"
          error={hasError('dailyIncrement')}
          helperText={
            hasError('dailyIncrement') && formik.errors.dailyIncrement
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dailyIncrement}
          required
        />
      </form>
    </CustomDialog>
  );
};

export default ModifyDailyIncrementValuesForm;
