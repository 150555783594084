import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
// components
import App from './containers/App';
// material ui core components
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// redux reducers
import authReducer from './store/reducers/auth';
import businessesReducer from './store/reducers/businesses';
import usersReducer from './store/reducers/users';
import toastReducer from './store/reducers/toast';
import calendarsReducer from './store/reducers/calendars';
// styles
import './index.css';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#1470af',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  businesses: businessesReducer,
  users: usersReducer,
  toast: toastReducer,
  calendars: calendarsReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const app = (
  <ReactQueryConfigProvider
    config={{ queries: { refetchOnWindowFocus: false } }}
  >
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  </ReactQueryConfigProvider>
);

ReactDOM.render(app, document.getElementById('root'));
