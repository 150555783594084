import React from 'react';
import PropTypes from 'prop-types';
// material ui core components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CustomDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        {props.cancelButtonText && (
          <Button onClick={props.onClose} color="primary">
            {props.cancelButtonText}
          </Button>
        )}
        {props.okButtonText && (
          <Button onClick={props.onConfirmation} color="primary" autoFocus>
            {props.okButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

export default CustomDialog;
