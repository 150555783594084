import React from 'react';
import { TextField } from '@material-ui/core';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from '../axios';
// components
import GridContainer from './UI/Grid/GridContainer';
import GridItem from './UI/Grid/GridItem';
import CustomDialog from './UI/CustomDialog/CustomDialog';
import classes from './MonthDetailPayDayForm.module.css';

const editMassivePayDayMutation = ({ monthIds, payDay }) => {
  return axios
    .patch(`/months/batch`, { monthIds, payload: { payDay } })
    .then((res) => res.data.data);
};

const compareMonthDays = (months) => {
  const monthDays = months.map((m) => m.payDay);
  const daysSet = new Set(monthDays);
  const days = Array.from(daysSet);
  if (days.length > 1) {
    return 'varios valores';
  } else if (days.includes(null)) {
    return 'aún no seteado';
  } else {
    return days[0];
  }
};

const MonthDetailPayDayForm = ({
  selectedUsers,
  monthDetail,
  open,
  onCloseDialog,
  onMutationSuccess,
}) => {
  const [mutate] = useMutation(editMassivePayDayMutation, {
    onSuccess: async () => {
      formik.resetForm();
      onMutationSuccess();
      onCloseDialog();
    },
  });

  let selectedMonths = [];
  if (monthDetail) {
    selectedMonths = monthDetail
      .filter((x) => selectedUsers.some((y) => x.userId === y))
      .map((user) => user.months[0]);
  }

  const formik = useFormik({
    initialValues: {
      payDay: '',
    },
    validationSchema: yup.object().shape({
      payDay: yup
        .number()
        .min(1, 'El valor mínimo es 1')
        .max(31, 'El valor máximo es 31'),
    }),
    onSubmit: (values) => {
      mutate({
        monthIds: selectedMonths.map((month) => month.monthId),
        payDay: values.payDay || null,
      });
    },
  });

  return (
    <CustomDialog
      title="Indique el día de pago de nómina"
      description=""
      open={open}
      onConfirmation={formik.handleSubmit}
      onClose={onCloseDialog}
      okButtonText="Confirmar"
      cancelButtonText="Cancelar"
    >
      <div>
        <p>Día pago de nómina actual: {compareMonthDays(selectedMonths)}</p>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            className={classes.Input}
            type="number"
            margin="dense"
            variant="outlined"
            name="payDay"
            label="Elegir día"
            error={formik.touched.payDay && formik.errors.payDay}
            helperText={formik.touched.payDay && formik.errors.payDay}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.payDay}
          />
        </GridItem>
      </GridContainer>
    </CustomDialog>
  );
};

export default MonthDetailPayDayForm;
