import * as actionTypes from './actionTypes';
import axios from '../../axios';

export const fetchCalendars = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/calendar-templates');
    dispatch({ type: actionTypes.FETCH_CALENDARS, calendars: data.data });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createCalendar = (calendarData) => async (dispatch) => {
  try {
    const { data } = await axios.post('/calendar-templates', calendarData);
    dispatch({ type: actionTypes.CREATE_CALENDAR, calendar: data.data });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const editCalendar = (calendarTemplateId, calendarData) => async (
  dispatch,
) => {
  try {
    await axios.patch(
      `/calendar-templates/${calendarTemplateId}`,
      calendarData,
    );
    dispatch({
      type: actionTypes.EDIT_CALENDAR,
      calendar: { calendarTemplateId, ...calendarData },
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteCalendar = (calendarTemplateId) => async (dispatch) => {
  try {
    await axios.delete(`/calendar-templates/${calendarTemplateId}`);
    dispatch({
      type: actionTypes.DELETE_CALENDAR,
      payload: calendarTemplateId,
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};
