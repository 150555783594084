import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { removeToast } from '../../store/actions';
// components
import Header from '../../components/Header/Header';
import SideDrawer from '../../components/UI/SideDrawer/SideDrawer';
import Snackbar from '../../components/UI/Snackbar/Snackbar';
// material ui core components
import { CheckCircle, Error as ErrorIcon } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
// routes
import routes from '../../routes/index';
// styles
import layoutStyle from '../../assets/jss/layout.jsx';

const SNACKBAR_ICON = {
  success: CheckCircle,
  danger: ErrorIcon,
};

class Layout extends Component {
  state = {
    showSideDrawer: false,
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        <SideDrawer
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
          onItemSelected={this.sideDrawerClosedHandler}
          handleDrawerOpen={this.sideDrawerToggleHandler}
          routes={routes}
          onLogout={this.props.onLogout}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Header
            drawerToggleClicked={this.sideDrawerToggleHandler}
            routes={routes}
            onLogout={this.props.onLogout}
            {...rest}
          />
          <div className={classes.content}>{this.props.children}</div>
        </div>
        <Snackbar
          place="bl"
          color={this.props.toastMessageType}
          icon={SNACKBAR_ICON[this.props.toastMessageType]}
          message={this.props.toastMessage}
          open={this.props.showToast}
          closeNotification={this.props.onCloseToast}
          close
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showToast: state.toast.show,
    toastMessage: state.toast.message,
    toastMessageType: state.toast.messageType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseToast: () => dispatch(removeToast()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(layoutStyle)(Layout)));
