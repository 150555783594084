import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import axios from '../../axios';
// components
import TableList from '../../components/UI/TableList/TableList';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
// material ui core components
import { withStyles } from '@material-ui/core/styles';
// styles
import listsContainerStyles from '../../assets/jss/containers/listsContainer';

const adminsQuery = () => {
  return axios.get('/admins').then((res) => res.data.data);
};

const deleteAdminMutation = ({ adminId }) => {
  return axios.delete(`/admins/${adminId}`).then((res) => res.data.data);
};

const PAGE_LIMIT = 12;

const Administrators = () => {
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();

  const { data, isLoading } = useQuery('admins', adminsQuery);

  const [deleteAdmin] = useMutation(deleteAdminMutation, {
    onSettled: () => {
      setAdminToDelete(null);
    },
  });

  const pageClickHandler = ({ currentPage }) => {
    setCurrentPage(currentPage);
  };

  const createAdminHandler = () => {
    history.push({
      pathname: '/administrators/create',
      state: {
        editMode: false,
      },
    });
  };

  const editAdminHandler = (adminId) => {
    const admin = data.find((a) => a.adminId === adminId);
    history.push({
      pathname: `/administrators/${adminId}/edit`,
      state: {
        admin,
        editMode: true,
      },
    });
  };

  const deleteAdminHandler = (adminId) => {
    const admin = data.find((a) => a.adminId === adminId);
    setAdminToDelete(admin);
  };

  if (isLoading) {
    return <Spinner />;
  }

  let tableContent = null;

  if (data.length > 0) {
    const adminsData = data
      .slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
      .map((admin) => {
        return [
          admin.adminId,
          `${admin.firstName} ${admin.lastName}`,
          admin.email,
          admin.businesses.map((b) => b.name).join(', '),
        ];
      });

    tableContent = (
      <TableList
        title="Administradores"
        head={['Id', 'Nombre Completo', 'Email', 'Entidades', '']}
        data={adminsData}
        delete
        onDelete={deleteAdminHandler}
        edit
        onEdit={editAdminHandler}
        pagination={
          <Pagination
            totalRecords={data.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={pageClickHandler}
          />
        }
      />
    );
  }

  let deleteMessage = '';
  if (adminToDelete) {
    deleteMessage = `¿Estás seguro que deseas eliminar el administrador ${adminToDelete.firstName} ${adminToDelete.lastName}?`;
  }

  return (
    <>
      {tableContent}
      <AddButton onClick={createAdminHandler} />
      <CustomDialog
        title="Confirmar"
        description={deleteMessage}
        open={!!adminToDelete}
        onConfirmation={() => deleteAdmin({ adminId: adminToDelete?.adminId })}
        onClose={() => setAdminToDelete(null)}
        okButtonText="Eliminar"
        cancelButtonText="Cancelar"
      />
    </>
  );
};

export default withStyles(listsContainerStyles)(Administrators);
