/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
// components
import Logo from '../../../components/Logo/Logo';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
// material ui icons
import Menu from '@material-ui/icons/Menu';
import Logout from '@material-ui/icons/ExitToApp';
// styles
import sidebarStyle from '../../../assets/jss/components/sidebarStyle.jsx';
import myClasses from './SideDrawer.module.css';
import { useSelector } from 'react-redux';

const SideDrawer = ({ ...props }) => {
  const { classes, routes } = props;

  const { user } = useSelector((state) => state.auth);

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (
          prop.redirect ||
          !prop.sidebarName ||
          (prop.onlySuperAdmin && !user.isSuperAdmin)
        )
          return null;
        return (
          <NavLink
            to={prop.path}
            className={classes.item}
            activeClassName={myClasses.Active}
            key={key}
            {...(prop.path === '/' ? { exact: true } : {})}
          >
            <ListItem button className={classes.itemLink}>
              <ListItemIcon className={classes.itemIcon}>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <prop.icon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={prop.sidebarName}
                className={classNames([classes.itemText, myClasses.ItemText])}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
      {props.width === 'xs' || props.width === 'sm' ? (
        <ListItem button className={classes.itemLink} onClick={props.onLogout}>
          <ListItemIcon className={classes.itemIcon}>
            <Logout />
          </ListItemIcon>
          <ListItemText
            primary="Cerrar Sesión"
            className={classNames([classes.itemText, myClasses.ItemText])}
            disableTypography={true}
          />
        </ListItem>
      ) : null}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <div className={classes.logoImage}>
        <Logo />
      </div>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawerOpen}
        >
          <Menu />
        </IconButton>
        <Drawer
          anchor="left"
          variant="temporary"
          color="primary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={props.open}
          onClose={props.handleDrawerOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
        </Drawer>
      </Hidden>
    </div>
  );
};

SideDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(sidebarStyle)(withWidth()(withRouter(SideDrawer)));
