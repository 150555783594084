import React, { useState, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { setToast } from '../../store/actions';
import axios from '../../axios';
// components
import TableList from '../../components/UI/TableList/TableList';
import MonthDetailForm from '../../components/MonthDetailForm';
import MonthDetailSearch from '../../components/MonthDetailSearch';
import Spinner from '../../components/UI/Spinner/Spinner';
import GridContainer from '../../components/UI/Grid/GridContainer';
import GridItem from '../../components/UI/Grid/GridItem';
import MonthDetailMassiveForm from '../../components/MonthDetailMassiveForm';
import MonthDetailPayDayForm from '../../components/MonthDetailPayDayForm';
import Input from '../../components/UI/Input/Input';
import Pagination from '../../components/UI/Pagination/Pagination';
// material ui core components
import { Button } from '@material-ui/core';
import { Send, GetApp, CloudUpload } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
// styles
import listsContainerStyles from '../../assets/jss/containers/listsContainer';
import classes from './MonthDetail.module.css';

const PAGE_LIMIT = 12;

const fetchMonthDetail = (key, params) => {
  return axios
    .get('/users/month-view', { params })
    .then((res) => res.data.data);
};

const uploadMonthCsv = ({ file, year, month }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('month', month);
  formData.append('year', year);
  return axios
    .post('/calendar-templates/import', formData)
    .then((res) => res.data.data);
};

const getDaysFromMonth = (month = 1, year = 2020) => {
  const days = [];
  const daysInMonth = moment()
    .month(month - 1)
    .year(year)
    .daysInMonth();
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i.toString());
  }
  return days;
};

const createExportableData = (data) => {
  return data.map((row) => {
    return row.map((field) => {
      if (typeof field === 'string' && field.startsWith('$')) {
        return field.replace(/[^0-9]/g, '');
      }
      return field;
    });
  });
};

const MonthDetail = () => {
  const [searchData, setSearchData] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showMassiveForm, setShowMassiveForm] = useState(false);
  const [showPayDayForm, setShowPayDayForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const inputRef = useRef();

  const dispatch = useDispatch();

  const monthDetailState = useQuery(
    ['monthDetail', searchData],
    fetchMonthDetail,
    {
      enabled: searchData,
      onError: (error) => {
        let errorMessage = 'Error al obtener el detalle del mes seleccionado';
        if (error?.response?.data) {
          errorMessage = error?.response?.data?.showMessage?.ES;
        }
        dispatch(setToast(errorMessage, 'danger'));
      },
    },
  );

  const [uploadCsv, uploadState] = useMutation(uploadMonthCsv, {
    onSuccess: (data) => {
      monthDetailState.refetch();
      dispatch(setToast('El listado del mes fue importado con éxito!'));
    },
    onError: (error) => {
      dispatch(setToast('Error al importar el listado del mes', 'danger'));
    },
  });

  const handleSearch = (businessId, month, year) => {
    setSearchData({ businessId, month, year });
  };

  const handleImportFile = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };

  const handleSelectFile = (event) => {
    uploadCsv({
      year: searchData.year,
      month: searchData.month,
      file: event.target.files[0],
    });
  };

  const tableHead = [
    'Id',
    'Nombre',
    'RUT',
    ...getDaysFromMonth(searchData?.month, searchData?.year),
  ];

  const tableData = monthDetailState.data
    ?.filter((user) => {
      return ['firstName', 'lastName', 'email'].some((key) => {
        return user[key].toLowerCase().includes(searchText.toLowerCase());
      });
    })
    .slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
    .map((user) => {
      const fullName = `${user.firstName} ${user.lastName}`;
      return [
        user.userId,
        fullName,
        user.RUT,
        ...user.months[0].days.map((day) => {
          const wasModified =
            day.isFrozen ||
            day.notes ||
            !isNaN(parseInt(day.overrideDayVal)) ||
            !isNaN(parseInt(day.overrideIncreaseVal));
          return `$${day.accum}${wasModified ? '*' : ''}`;
        }),
      ];
    });

  const clickCellHandler = (userId, index) => {
    const user = monthDetailState.data.find((u) => u.userId === userId);
    const day = user.months[0].days[index - 3];
    setSelectedDay({
      user: {
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        accumAmount: user.accumAmount,
      },
      day: {
        dayId: day.dayId,
        accum: day.accum,
        date: moment()
          .set('date', day.day)
          .set('month', searchData.month - 1)
          .set('year', searchData.year)
          .format('DD/MM/YYYY'),
        isWorkday: day.isWorkday,
        isFrozen: day.isFrozen || false,
        overrideDayVal: !isNaN(parseInt(day.overrideDayVal))
          ? day.overrideDayVal
          : '',
        overrideIncreaseVal: !isNaN(parseInt(day.overrideIncreaseVal))
          ? day.overrideIncreaseVal
          : '',
        notes: day.notes,
      },
    });
  };

  const filters = (
    <GridContainer className={classes.Search}>
      <GridItem xs={12} sm={12} md={4}>
        <Input
          element={{
            label: 'Buscar..',
            elementType: 'input',
            elementConfig: {
              type: 'text',
              placeholder: 'Buscar..',
            },
            value: searchText,
            validation: {},
          }}
          onChange={(value) => setSearchText(value)}
        />
      </GridItem>
      {selected.length > 0 && (
        <>
          <GridItem container alignItems="center" xs={12} sm={12} md={4}>
            <Button onClick={() => setShowMassiveForm(true)}>
              <Send style={{ marginRight: '0.5rem' }} /> MODIFICAR SELECCIONADOS
            </Button>
          </GridItem>
          <GridItem container alignItems="center" xs={12} sm={12} md={4}>
            <Button onClick={() => setShowPayDayForm(true)}>
              <Send style={{ marginRight: '0.5rem' }} /> CAMBIAR DÍA PAGO NÓMINA
            </Button>
          </GridItem>
        </>
      )}
    </GridContainer>
  );

  let table = null;
  if (monthDetailState.isLoading || uploadState.isLoading) {
    table = <Spinner />;
  } else if (monthDetailState.data) {
    table = (
      <TableList
        filters={filters}
        selectable
        selected={selected}
        onSelect={(value) => setSelected(value)}
        title="Detalle de un mes"
        head={tableHead}
        data={tableData}
        clickable
        onCellClick={clickCellHandler}
        style={{ minWidth: 2000 }}
        headerActions={
          <div>
            <span>
              <a
                href="#/"
                onClick={handleImportFile}
                className={classes.Button}
              >
                <CloudUpload
                  className={classes.ButtonIcon}
                  style={{ marginRight: '0.5rem' }}
                />{' '}
                <span>Importar Listado</span>
              </a>
              <input
                type="file"
                accept=".csv"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={handleSelectFile}
              />
            </span>
            <CSVLink
              enclosingCharacter=""
              separator=";"
              filename={`sueldoYa_detalle_${searchData?.month}-${searchData?.year}.csv`}
              headers={tableHead}
              data={createExportableData(tableData)}
              className={classes.Button}
            >
              <GetApp className={classes.ButtonIcon} />{' '}
              <span>Descargar Listado</span>
            </CSVLink>
          </div>
        }
        pagination={
          <Pagination
            totalRecords={monthDetailState.data.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={({ currentPage }) => setCurrentPage(currentPage)}
          />
        }
      />
    );
  }

  return (
    <React.Fragment>
      <MonthDetailSearch onSearch={handleSearch} />
      <MonthDetailForm
        searchData={searchData}
        selectedDay={selectedDay}
        onCloseDialog={() => setSelectedDay(null)}
        onMutationSuccess={monthDetailState.refetch}
      />
      <MonthDetailMassiveForm
        monthDetail={monthDetailState.data}
        selectedUsers={selected}
        open={showMassiveForm}
        days={getDaysFromMonth(searchData?.month, searchData?.year)}
        onMutationSuccess={monthDetailState.refetch}
        onCloseDialog={() => setShowMassiveForm(false)}
      />
      <MonthDetailPayDayForm
        monthDetail={monthDetailState.data}
        selectedUsers={selected}
        open={showPayDayForm}
        onMutationSuccess={monthDetailState.refetch}
        onCloseDialog={() => setShowPayDayForm(false)}
      />
      {table}
    </React.Fragment>
  );
};

export default withStyles(listsContainerStyles)(MonthDetail);
