import axios from 'axios';
import { store } from './index';
import { forceLogout } from './store/actions';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (err) => {
    if (
      err.response.data.errorCode === 1001 ||
      err.response.data.errorCode === 1004
    ) {
      store.dispatch(forceLogout());
    }
    return Promise.reject(err);
  },
);

export default instance;
