import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { editUser, createUser, setToast } from '../../../store/actions';
import { useFormik } from 'formik';
import { useQuery } from 'react-query';
import moment from 'moment';
import * as yup from 'yup';
// components
import GridItem from '../../../components/UI/Grid/GridItem';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import CardHeader from '../../../components/UI/Card/CardHeader';
import Button from '../../../components/UI/CustomButtons/Button';
import OutlinedSelect from '../../../components/UI/Input/OutlinedSelect/OutlinedSelect';
import RUTField from '../../../components/UI/RUTField/RUTField';
import calendarTemplatesQuery from '../../../operations/queries/calendarTemplates';
import businessesQuery from '../../../operations/queries/businesses';
import banksQuery from '../../../operations/queries/banks';
// material ui components
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
// styles
import classes from './UserForm.module.css';

const UserForm = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { userId } = params;
  const { editMode, user } = location.state;

  const { isSuperAdmin } = useSelector((state) => state.auth.user);

  const banksInfo = useQuery('banks', banksQuery, {
    onSuccess: (data) => {
      if (!editMode) {
        formik.setFieldValue('bankId', data[0]?.bankId);
      }
    },
  });

  const businessesInfo = useQuery('businesses', businessesQuery, {
    onSuccess: (data) => {
      if (!editMode) {
        formik.setFieldValue('businessId', data[0]?.businessId);
      }
    },
  });

  const calendarTemplatesInfo = useQuery('calendarTemplates', calendarTemplatesQuery, {
    onSuccess: (data) => {
      if (!editMode) {
        formik.setFieldValue('defaultCalendarTemplateId', data[0]?.calendarTemplateId);
      }
    },
  });

  const formik = useFormik({
    validateOnMount: editMode,
    initialValues: {
      firstName: editMode ? user.firstName : '',
      lastName: editMode ? user.lastName : '',
      email: editMode ? user.email : '',
      phone: editMode ? user.phone || '' : '',
      RUT: editMode ? user.RUT : '',
      businessId: editMode ? user.businessId : '',
      salary: editMode ? user.salary : '',
      bankId: editMode ? user.bankId : '',
      accountNumber: editMode ? user.accountNumber : '',
      rateAmount: editMode ? user.rateAmount : 0,
      ratePercentage: editMode ? user.ratePercentage : 0,
      payDay: editMode ? user.payDay : 1,
      payHour: editMode ? user.payHour : 20,
      payInCurrentMonth: editMode ? user.payInCurrentMonth : false,
      accumType: editMode ? user.accumType : 'FIXED',
      accumValue: editMode ? (user.accumType === 'FIXED' ? user.accumAmount : user.accumPercentage) : '',
      defaultCalendarTemplateId: editMode ? user.defaultCalendarTemplateId : '',
      dailyIncrement: editMode ? user.dailyIncrement : '',
      isAdvanceProgrammed: editMode ? user.isAdvanceProgrammed : false,
      advanceAmount: editMode ? user.advanceAmount || '' : '',
      advanceDate: editMode
        ? (user.advanceDate && moment(user.advanceDate).utc().format('YYYY-MM-DD')) || ''
        : '',
    },
    validationSchema: yup.object().shape(
      {
        firstName: yup.string().required('Requerido'),
        lastName: yup.string().required('Requerido'),
        email: yup
          .string()
          .email('Ingrese un email válido')
          .when('phone', {
            is: '',
            then: yup.string().required('Requerido'),
          }),
        phone: yup.string().when('email', {
          is: '',
          then: yup.string().required('Requerido'),
        }),
        RUT: yup.string().required('Requerido'),
        salary: yup.number().required('Requerido'),
        accountNumber: yup.string().required('Requerido'),
        rateAmount: isSuperAdmin
          ? yup
              .number()
              .min(0, 'El valor mínimo es 0')
              .when('ratePercentage', {
                is: (value) => parseInt(value) > 0,
                then: yup.number().max(0, 'Al menos uno de los dos atributos debe ser 0'),
              })
          : yup.number(),
        ratePercentage: isSuperAdmin
          ? yup
              .number()
              .min(0, 'El valor mínimo es 0')
              .when('rateAmount', {
                is: (value) => parseInt(value) > 0,
                then: yup.number().max(0, 'Al menos uno de los dos atributos debe ser 0'),
              })
          : yup.number(),
        payDay: isSuperAdmin ? yup.number().required('Requerido') : yup.number(),
        payHour: yup.number(),
        dailyIncrement: yup.number().required('Requerido'),
        accumValue: yup
          .number()
          .required('Requerido')
          .min(0, 'El valor mínimo es 0')
          .when('accumType', {
            is: 'PERCENTAGE',
            then: yup.number().min(0, 'El porcentaje mínimo es 0').max(100, 'El porcentaje máximo es 100'),
          }),
        advanceAmount: yup
          .string()
          .nullable()
          .when('isAdvanceProgrammed', {
            is: true,
            then: yup.string().required('Requerido').min(0, 'El monto mínimo es 0'),
          }),
        advanceDate: yup
          .string()
          .nullable()
          .when('isAdvanceProgrammed', {
            is: true,
            then: yup.string().required('Requerido'),
          }),
      },
      [
        ['email', 'phone'],
        ['ratePercentage', 'rateAmount'],
      ],
    ),
    onSubmit: async (values) => {
      const body = {
        ...values,
        advanceAmount: values.advanceAmount || 0,
        advanceDate: values.advanceDate || new Date().toISOString(),
        accumValue: undefined,
      };

      if (values.accumType === 'FIXED') {
        body.accumAmount = values.accumValue;
      } else {
        body.accumPercentage = values.accumValue;
      }

      try {
        if (editMode) {
          await dispatch(editUser(userId, body));
          dispatch(setToast('El usuario fue editado con éxito'));
        } else {
          await dispatch(createUser(body));
          dispatch(setToast('El usuario fue creado con éxito'));
        }
        history.push('/users');
      } catch (error) {
        if ([1005, 3006].includes(error?.response?.data?.errorCode)) {
          formik.setFieldError('RUT', 'Ya existe un usuario con el RUT ingresado');
        }
        if (editMode) {
          dispatch(setToast('Se produjo un error al editar el usuario', 'danger'));
        } else {
          dispatch(setToast('Se produjo un error al crear el usuario', 'danger'));
        }
      }
    },
  });

  useEffect(() => {
    if (!editMode) {
      if (formik.values.businessId === 1 || formik.values.businessId === 2) {
        formik.setFieldValue('payDay', 28);
        formik.setFieldValue('payInCurrentMonth', true);
      }
    }
  }, [formik.values.businessId]);

  const hasError = (name) => !!formik.errors[name] && formik.touched[name];
  const getError = (name) => formik.touched[name] && formik.errors[name];

  const onCheckboxChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.checked);
  };

  const banksOptions = (banksInfo.data || []).map((bank) => {
    return {
      value: bank.bankId,
      label: `${bank.bankId} - ${bank.name}`,
    };
  });

  const businessesOptions = (businessesInfo.data || [])
    .sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    })
    .map((business) => {
      return {
        value: business.businessId,
        label: business.name,
      };
    });

  const calendarTemplateOptions = (calendarTemplatesInfo.data || [])
    .sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    })
    .map((calendarType) => {
      return {
        value: calendarType.calendarTemplateId,
        label: calendarType.name,
      };
    });

  return (
    <Card profile className={classes.Card}>
      <CardHeader>
        <h1 className={classes.Title}>{editMode ? 'Editar' : 'Crear'} Usuario</h1>
      </CardHeader>
      <CardBody profile>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="firstName"
                type="text"
                margin="dense"
                variant="outlined"
                label="Nombre"
                error={hasError('firstName')}
                helperText={getError('firstName')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="lastName"
                type="text"
                margin="dense"
                variant="outlined"
                label="Apellido"
                error={hasError('lastName')}
                helperText={getError('lastName')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="email"
                type="email"
                margin="dense"
                variant="outlined"
                label="Email"
                error={hasError('email')}
                helperText={getError('email')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="phone"
                type="text"
                margin="dense"
                variant="outlined"
                label="Celular"
                error={hasError('phone')}
                helperText={getError('phone')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <RUTField
                className={classes.Input}
                name="RUT"
                type="text"
                margin="dense"
                variant="outlined"
                label="Documento de identidad"
                error={hasError('RUT')}
                helperText={getError('RUT')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.RUT}
                required
              />
            </GridItem>
            <GridItem md={6} />
            <GridItem xs={12} sm={12} md={6}>
              <OutlinedSelect
                name="businessId"
                disabled={businessesInfo.isLoading || businessesInfo.isError}
                label="Entidad"
                className={classes.Input}
                value={formik.values.businessId}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={businessesOptions}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="salary"
                type="number"
                margin="dense"
                variant="outlined"
                label="Sueldo"
                error={hasError('salary')}
                helperText={getError('salary')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.salary}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <OutlinedSelect
                name="bankId"
                disabled={banksInfo.isLoading || banksInfo.isError}
                label="Banco"
                className={classes.Input}
                value={formik.values.bankId}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={banksOptions}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="accountNumber"
                type="text"
                margin="dense"
                variant="outlined"
                label="Número de cuenta"
                error={hasError('accountNumber')}
                helperText={getError('accountNumber')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accountNumber}
                required
              />
            </GridItem>
            {isSuperAdmin && (
              <>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    className={classes.Input}
                    name="rateAmount"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    label="Monto Tarifa"
                    error={hasError('rateAmount')}
                    helperText={getError('rateAmount')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.rateAmount}
                    required
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    className={classes.Input}
                    name="ratePercentage"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    label="Porcentaje Tarifa"
                    error={hasError('ratePercentage')}
                    helperText={getError('ratePercentage')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ratePercentage}
                    required
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    className={classes.Input}
                    name="payDay"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    label="Día de Pago Nómina"
                    error={hasError('payDay')}
                    helperText={getError('payDay')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.payDay}
                    required
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    className={classes.Input}
                    name="payHour"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    label="Hora de acumulación"
                    error={hasError('payHour')}
                    helperText={getError('payHour')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.payHour}
                    required
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} container justify="flex-start">
                  <FormControlLabel
                    label="Cobra mes corriente"
                    control={
                      <Checkbox
                        name="payInCurrentMonth"
                        color="primary"
                        checked={formik.values.payInCurrentMonth}
                        onChange={onCheckboxChange}
                      />
                    }
                  />
                </GridItem>
              </>
            )}
            <GridItem xs={12} sm={12} md={6}>
              <OutlinedSelect
                name="accumType"
                label="Tipo de Acumulación Máxima"
                className={classes.Input}
                value={formik.values.accumType}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={[
                  { value: 'PERCENTAGE', label: 'Valor porcentual' },
                  { value: 'FIXED', label: 'Valor fijo' },
                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="accumValue"
                type="number"
                margin="dense"
                variant="outlined"
                label="Acumulación máxima mensual"
                error={hasError('accumValue')}
                helperText={getError('accumValue')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accumValue}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <OutlinedSelect
                name="defaultCalendarTemplateId"
                disabled={calendarTemplatesInfo.isLoading || calendarTemplatesInfo.isError}
                label="Tipo De Calendario"
                className={classes.Input}
                value={formik.values.defaultCalendarTemplateId}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={calendarTemplateOptions}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="dailyIncrement"
                type="number"
                margin="dense"
                variant="outlined"
                label="Incremento Diario"
                error={hasError('dailyIncrement')}
                helperText={getError('dailyIncrement')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dailyIncrement}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} container justify="flex-start">
              <FormControlLabel
                label="Tiene anticipo programado"
                control={
                  <Checkbox
                    name="isAdvanceProgrammed"
                    color="primary"
                    checked={formik.values.isAdvanceProgrammed}
                    onChange={onCheckboxChange}
                  />
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                className={classes.Input}
                name="advanceAmount"
                type="number"
                margin="dense"
                variant="outlined"
                label="Monto anticipo programado"
                error={hasError('advanceAmount')}
                helperText={getError('advanceAmount')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.advanceAmount}
                required={formik.values.isAdvanceProgrammed}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                className={classes.Input}
                name="advanceDate"
                type="date"
                margin="dense"
                variant="outlined"
                label="Fecha anticipo programado"
                error={hasError('advanceDate')}
                helperText={getError('advanceDate')}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.advanceDate}
                required={formik.values.isAdvanceProgrammed}
              />
            </GridItem>
          </GridContainer>
        </form>
      </CardBody>
      <CardFooter>
        <Button
          className={classes.Button}
          color="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || formik.isSubmitting}
        >
          {editMode ? 'ACTUALIZAR' : 'CREAR'} USUARIO
        </Button>
      </CardFooter>
    </Card>
  );
};

export default UserForm;
