import React from 'react';
// material ui core components
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/core/styles/withStyles';
// styles
import buttonStyle from '../../../assets/jss/components/buttonStyle.jsx';

const AddButton = (props) => {
  const { classes } = props;
  return (
    <Fab
      color="primary"
      aria-label="Add"
      className={classes.addButton}
      onClick={props.onClick}>
      <AddIcon />
    </Fab>
  );
}

export default withStyles(buttonStyle)(AddButton);