import React from 'react';
import { useQuery } from 'react-query';
import axios from '../../axios';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
// components
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/Card/CardHeader';
import CardFooter from '../../components/UI/Card/CardFooter';
import CardIcon from '../../components/UI/Card/CardIcon';
import Spinner from '../../components/UI/Spinner/Spinner';
// @material-ui/icons
import { People, LocalAtm } from '@material-ui/icons';
// styles
import dashboardStyle from '../../assets/jss/containers/dashboardStyle';
import styles from './Dashboard.module.css';
import { Link } from 'react-router-dom';

const fetchDashboardInfo = () => {
  return axios.get('/dashboard').then((res) => res.data.data);
};

const Dashboard = ({ classes }) => {
  const { data, isLoading } = useQuery('dashboard', fetchDashboardInfo);

  let dashboard = null;
  if (isLoading) {
    dashboard = <Spinner />;
  } else if (data) {
    dashboard = (
      <GridContainer>
        <GridItem xs={12} md={6}>
          <Link to="/requests">
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <LocalAtm />
                </CardIcon>
                <p className={classes.cardCategory}>Solicitudes pendientes</p>
                <h3 className={classes.cardTitle}>{data.pendingRqstsQty}</h3>
              </CardHeader>
              <CardFooter />
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} md={6}>
          <Link to="/requests">
            <Card>
              <CardHeader
                className={styles.PrimaryColorHeader}
                color="primary"
                stats
                icon
              >
                <CardIcon color="primary">
                  <LocalAtm />
                </CardIcon>
                <p className={classes.cardCategory}>Solicitudes hechas hoy</p>
                <h3 className={classes.cardTitle}>{data.todayRqstsQty}</h3>
              </CardHeader>
              <CardFooter />
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} md={6}>
          <Link to="/requests">
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <LocalAtm />
                </CardIcon>
                <p className={classes.cardCategory}>Solicitudes pagadas</p>
                <h3 className={classes.cardTitle}>{data.paidRqstsQty}</h3>
              </CardHeader>
              <CardFooter />
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} md={6}>
          <Link to="/users">
            <Card>
              <CardHeader color="rose" stats icon>
                <CardIcon color="rose">
                  <People />
                </CardIcon>
                <p className={classes.cardCategory}>Usuarios registrados hoy</p>
                <h3 className={classes.cardTitle}>
                  {data.todayRegisteredUsers}
                </h3>
              </CardHeader>
              <CardFooter />
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} md={6}>
          <Link to="/requests">
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <LocalAtm />
                </CardIcon>
                <p className={classes.cardCategory}>Solicitudes rechazadas</p>
                <h3 className={classes.cardTitle}>{data.rejectedRqstsQty}</h3>
              </CardHeader>
              <CardFooter />
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} md={6}>
          <Link to="/users">
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <People />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Total usuarios registrados
                </p>
                <h3 className={classes.cardTitle}>{data.registeredUsersQty}</h3>
              </CardHeader>
              <CardFooter />
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
    );
  }

  return <div className={styles.Dashboard}>{dashboard}</div>;
};

export default withStyles(dashboardStyle)(Dashboard);
