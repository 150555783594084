import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';

const getRawInput = (input) => input.replace(/[^0-9A-Za-z]/g, '').toUpperCase();
const getInitialMask = (value) => (value.length === 9 ? '99.999.999-*' : '9.999.999-*');

const RutField = ({ onChange, ...props }) => {
  const [mask, setMask] = useState(getInitialMask(props.value || ''));

  const onValueChange = (event) => {
    onChange({
      ...event,
      target: {
        ...event.target,
        name: props.name,
        value: getRawInput(event.target.value),
      },
    });
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    const value = getRawInput(oldState.value);

    if (value.length === 8 && value.match(/^\d+$/) && userInput) {
      setMask('99.999.999-*');

      return {
        selection: { start: 12, end: 12 },
        value: value + userInput,
      };
    }

    if (value.length <= 8) {
      setMask('9.999.999-*');
    }

    return newState;
  };

  return (
    <InputMask
      {...props}
      mask={mask}
      onChange={onValueChange}
      beforeMaskedValueChange={beforeMaskedValueChange}
    >
      {(inputProps) => <TextField {...inputProps} />}
    </InputMask>
  );
};

export default RutField;
