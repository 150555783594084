const actionTypeMap = {
  CREATED: 'creó',
  MODIFIED: 'modificó',
  DELETED: 'eliminó',
};

export const createAuditDescription = (audit) => {
  if (audit.type === 'MODIFIED' && audit.targetUser) {
    return audit.descr;
  }

  const author = audit.originatingAdmin ? 'ADMIN' : 'USUARIO';
  const action = actionTypeMap[audit.type];

  let target = '';
  if (audit.targetUser) {
    target = `al usuario ${audit.targetUser.firstName} ${audit.targetUser.lastName}`;
  } else if (audit.targetRequest) {
    target = `la solicitud ${audit.targetRequest.requestId}`;
  } else if (audit.targetBusiness) {
    target = `la entidad ${audit.targetBusiness.name}`;
  }

  return `${author} ${action} ${target}`;
};
