import React from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import sha256 from 'crypto-js/sha256';
import axios from '../../../axios';
import { setToast } from '../../../store/actions';
// components
import OutlinedSelect from '../../../components/UI/Input/OutlinedSelect/OutlinedSelect';
import GridItem from '../../../components/UI/Grid/GridItem';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import CardHeader from '../../../components/UI/Card/CardHeader';
import Button from '../../../components/UI/CustomButtons/Button';
// material ui components
import { TextField, Chip, Paper } from '@material-ui/core';
// styles
import classes from './AdministratorForm.module.css';

const createAdminMutation = (values) => {
  return axios.post('/admins', values).then((res) => res.data.data);
};

const editAdminMutation = ({ adminId, ...values }) => {
  return axios.patch(`/admins/${adminId}`, values).then((res) => res.data.data);
};

const businessesQuery = () => {
  return axios.get('/businesses').then((res) => res.data.data);
};

const AdministratorForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { adminId } = useParams();
  const { editMode, admin } = location.state;

  const businessesInfo = useQuery('businesses', businessesQuery);
  const [createAdmin] = useMutation(createAdminMutation);
  const [editAdmin] = useMutation(editAdminMutation);

  const formik = useFormik({
    isInitialValid: editMode,
    initialValues: {
      firstName: editMode ? admin.firstName : '',
      lastName: editMode ? admin.lastName : '',
      email: editMode ? admin.email : '',
      businesses: editMode
        ? admin.businesses.map((b) => ({ value: b.businessId, label: b.name }))
        : [],
      password: '',
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required('Requerido'),
      lastName: yup.string().required('Requerido'),
      email: yup
        .string()
        .required('Requerido')
        .email('Ingrese un email válido'),
      password: yup
        .string()
        .optional()
        .min(8, 'Debe tener al menos 8 caractéres'),
    }),
    onSubmit: async (values) => {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password ? sha256(values.password).toString() : null,
        businessIds: values.businesses.map((b) => b.value),
      };

      try {
        if (editMode) {
          await editAdmin({ adminId, ...body });
          dispatch(setToast('El administrador fue editado con éxito'));
        } else {
          await createAdmin(body);
          dispatch(setToast('El administrador fue creado con éxito'));
        }
        history.push('/administrators');
      } catch (err) {
        if (editMode) {
          dispatch(
            setToast(
              'Se produjo un error al editar el administrador',
              'danger',
            ),
          );
        } else {
          dispatch(
            setToast('Se produjo un error al crear el administrador', 'danger'),
          );
        }
      }
    },
  });

  const hasError = (name) => !!formik.errors[name] && formik.touched[name];

  const businesses = (businessesInfo?.data || [])
    .filter((b) => {
      return !formik.values.businesses.some((v) => v.value === b.businessId);
    })
    .map((b) => {
      return {
        value: b.businessId,
        label: b.name,
      };
    });

  const onSelectBusiness = (event) => {
    if (formik.values.businesses.some((b) => b.value === event.target.value)) {
      return;
    }

    const business = businessesInfo.data.find((b) => {
      return b.businessId === event.target.value;
    });

    const newBusinesses = [
      ...formik.values.businesses,
      { value: business.businessId, label: business.name },
    ];

    formik.setFieldValue('businesses', newBusinesses);
  };

  const onDeleteBusiness = (value) => {
    const newBusinesses = formik.values.businesses.filter((b) => {
      return b.value !== value;
    });
    formik.setFieldValue('businesses', newBusinesses);
  };

  return (
    <Card profile>
      <CardHeader>
        <h1 className={classes.Title}>
          {editMode ? 'Editar Administrador' : 'Crear Administrador'}
        </h1>
      </CardHeader>
      <CardBody profile>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="firstName"
                type="text"
                margin="dense"
                variant="outlined"
                label="Nombre"
                error={hasError('firstName')}
                helperText={hasError('firstName') && formik.errors.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="lastName"
                type="text"
                margin="dense"
                variant="outlined"
                label="Apellido"
                error={hasError('lastName')}
                helperText={hasError('lastName') && formik.errors.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="email"
                type="email"
                margin="dense"
                variant="outlined"
                label="Email"
                error={hasError('email')}
                helperText={hasError('email') && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                className={classes.Input}
                name="password"
                type="password"
                margin="dense"
                variant="outlined"
                label="Contraseña"
                error={hasError('password')}
                helperText={hasError('password') && formik.errors.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                required={!editMode}
              />
            </GridItem>
          </GridContainer>
          <h1 className={classes.Title}>Asociar entidades</h1>
          <GridContainer className={classes.EntityPicker}>
            <GridItem xs={12}>
              <OutlinedSelect
                disabled={businessesInfo.isLoading}
                label="Seleccione una entidad"
                className={classes.Input}
                onChange={onSelectBusiness}
                options={businesses}
                value=""
              />
            </GridItem>
            <GridItem xs={12}>
              {formik.values.businesses.length > 0 ? (
                <Paper className={classes.Paper}>
                  {formik.values.businesses.map((business) => (
                    <Chip
                      className={classes.Chip}
                      key={business.value}
                      label={business.label}
                      onDelete={() => onDeleteBusiness(business.value)}
                    />
                  ))}
                </Paper>
              ) : null}
            </GridItem>
          </GridContainer>
        </form>
      </CardBody>
      <CardFooter>
        <Button
          className={classes.Button}
          color="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
        >
          {editMode ? 'ACTUALIZAR ADMINISTRADOR' : 'CREAR ADMINISTRADOR'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default AdministratorForm;
