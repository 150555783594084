import * as actionTypes from './actionTypes';
import axios from '../../axios';

const createBusinessSuccess = (business) => {
  return {
    type: actionTypes.CREATE_BUSINESS_SUCCESS,
    business,
  };
};

export const createBusiness = (business) => async (dispatch) => {
  try {
    const { data } = await axios.post('/businesses', business);
    dispatch(createBusinessSuccess(data.data));
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const fetchBusinessesStart = () => {
  return {
    type: actionTypes.FETCH_BUSINESSES_START,
  };
};

const fetchBusinessesSuccess = (businesses) => {
  return {
    type: actionTypes.FETCH_BUSINESSES_SUCCESS,
    businesses,
  };
};

const fetchBusinessesFail = (error) => {
  return {
    type: actionTypes.FETCH_BUSINESSES_FAIL,
    error,
  };
};

export const fetchBusinesses = (active) => async (dispatch) => {
  dispatch(fetchBusinessesStart());
  try {
    const { data } = await axios.get('/businesses', {
      params: {
        active: active || undefined,
      },
    });
    dispatch(fetchBusinessesSuccess(data.data));
  } catch (err) {
    let errorMessage = 'Error al obtener el listado de empresas';
    dispatch(fetchBusinessesFail(errorMessage));
    throw err;
  }
};

const editBusinessSuccess = (business) => {
  return {
    type: actionTypes.EDIT_BUSINESS_SUCCESS,
    business,
  };
};

export const editBusiness = (businessId, businessData) => async (dispatch) => {
  try {
    await axios.patch(`/businesses/${businessId}`, businessData);
    dispatch(editBusinessSuccess({ businessId, ...businessData }));
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const deleteBusinessSuccess = (businessId) => {
  return {
    type: actionTypes.DELETE_BUSINESS_SUCCESS,
    businessId,
  };
};

export const deleteBusiness = (businessId) => async (dispatch) => {
  try {
    await axios.delete(`/businesses/${businessId}`);
    dispatch(deleteBusinessSuccess(businessId));
  } catch (err) {
    console.log(err);
    throw err;
  }
};
