import AWS from 'aws-sdk';

AWS.config.update({
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  }),
  region: process.env.REACT_APP_AWS_REGION,
});

const S3 = new AWS.S3({ region: process.env.REACT_APP_AWS_BUCKET_REGION });

export const getObject = (key) => {
  return new Promise((resolve, reject) => {
    S3.getObject(
      { Bucket: process.env.REACT_APP_AWS_BUCKET, Key: key },
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('data:image/jpeg;base64,' + encode(data.Body));
        }
      },
    );
  });
};

export const uploadObject = (file, key) => {
  return new Promise((resolve, reject) => {
    S3.upload(
      {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
        Body: file,
      },
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      },
    );
  });
};

export const deleteObject = (key) => {
  return new Promise((resolve, reject) => {
    S3.deleteObject(
      {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
      },
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      },
    );
  });
};

const encode = (data) => {
  const str = data.reduce(function (a, b) {
    return a + String.fromCharCode(b);
  }, '');
  return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
};
