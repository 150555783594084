import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from '../../axios';
import moment from 'moment';
// components
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import TableList from '../../components/UI/TableList/TableList';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import Input from '../../components/UI/Input/Input';
// material ui core components
import { withStyles } from '@material-ui/core/styles';
// styles
import listsContainerStyles from '../../assets/jss/containers/listsContainer';
import classes from './Audit.module.css';
import { createAuditDescription } from '../../utils/createAuditDescription';

const auditInfoQuery = () => {
  return axios.get('/audit').then((res) => res.data.data);
};

const PAGE_LIMIT = 12;

const auditTypeMap = {
  CREATED: 'Creación',
  MODIFIED: 'Edición',
  DELETED: 'Eliminación',
};

const Audit = () => {
  const [searchText, setSearchText] = useState('');
  const [originFilter, setOriginFilter] = useState('Todos');
  const [typeFilter, setTypeFilter] = useState('Todos');
  const [currentPage, setCurrentPage] = useState(1);

  let { data, isLoading } = useQuery('audit', auditInfoQuery);

  const pageClickHandler = ({ currentPage }) => {
    setCurrentPage(currentPage);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (data.length > 0) {
    let filteredAuditData = data;

    if (searchText) {
      filteredAuditData = filteredAuditData.filter((audit) => {
        return [
          'originatingAdmin.email',
          'originatingUser.RUT',
          'originatingUser.firstName',
          'originatingUser.lastName',
        ].some((key) => {
          const [parent, children] = key.split('.');

          if (!audit[parent]) {
            return false;
          }

          return audit[parent][children]
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });
      });
    }

    if (originFilter !== 'Todos') {
      filteredAuditData = filteredAuditData.filter((audit) => {
        return audit.origin === originFilter;
      });
    }

    if (typeFilter !== 'Todos') {
      filteredAuditData = filteredAuditData.filter((audit) => {
        return audit.type === typeFilter;
      });
    }

    const auditData = filteredAuditData
      .slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT)
      .map((audit) => {
        let author = '-';
        if (audit.originatingAdmin) {
          author = audit.originatingAdmin.email;
        } else if (audit.originatingUser) {
          author = `${audit.originatingUser.RUT} - ${audit.originatingUser.firstName} ${audit.originatingUser.lastName}`;
        }

        let target = '-';
        if (audit.targetRequest) {
          target = audit.targetRequest.requestId;
        } else if (audit.targetBusiness) {
          target = audit.targetBusiness.name;
        } else if (audit.targetUser) {
          target = `${audit.targetUser.RUT} - ${audit.targetUser.firstName} ${audit.targetUser.lastName}`;
        }

        return [
          audit.auditId,
          audit.auditId,
          moment(audit.createdAt).format('DD/MM/YYYY HH:mm'),
          audit.origin,
          author,
          target,
          auditTypeMap[audit.type],
          createAuditDescription(audit),
        ];
      });

    const filters = (
      <GridContainer className={classes.Search}>
        <GridItem xs={12} sm={12} md={3}>
          <Input
            element={{
              label: 'Buscar..',
              elementType: 'input',
              elementConfig: {
                type: 'text',
                placeholder: 'Buscar..',
              },
              value: searchText,
              validation: {},
            }}
            onChange={(value) => setSearchText(value)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Input
            element={{
              label: 'Origen',
              elementType: 'select',
              elementConfig: {
                variant: 'outlined',
                options: [
                  { value: 'Todos', label: 'Todos' },
                  { value: 'WEB', label: 'Web' },
                  { value: 'ADMIN', label: 'Admin' },
                ],
                mdUpSize: 6,
              },
              value: originFilter,
            }}
            onChange={(value) => setOriginFilter(value)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Input
            element={{
              label: 'Tipo',
              elementType: 'select',
              elementConfig: {
                variant: 'outlined',
                options: [
                  { value: 'Todos', label: 'Todos' },
                  { value: 'CREATED', label: 'Creación' },
                  { value: 'MODIFIED', label: 'Edición' },
                  { value: 'DELETED', label: 'Eliminación' },
                ],
                mdUpSize: 6,
              },
              value: typeFilter,
            }}
            onChange={(value) => setTypeFilter(value)}
          />
        </GridItem>
      </GridContainer>
    );

    return (
      <TableList
        title="Auditoría"
        head={[
          'Id',
          'ID',
          'Fecha',
          'Origen',
          'Autor',
          'Objetivo',
          'Tipo',
          'Descripción',
        ]}
        data={auditData}
        filters={filters}
        pagination={
          <Pagination
            totalRecords={data.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={pageClickHandler}
          />
        }
      />
    );
  }

  return null;
};

export default withStyles(listsContainerStyles)(Audit);
