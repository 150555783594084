import React, { useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import axios from '../axios';
// components
import GridContainer from './UI/Grid/GridContainer';
import GridItem from './UI/Grid/GridItem';
import CustomDialog from './UI/CustomDialog/CustomDialog';
// classes
import classes from './MonthDetailForm.module.css';

const editDayMutation = ({ dayId, ...params }) => {
  return axios
    .patch(`/calendar-templates/days/${dayId}`, params)
    .then((res) => res.data.data);
};

const MonthDetailForm = ({
  searchData,
  selectedDay,
  onCloseDialog,
  onMutationSuccess,
}) => {
  const [mutate] = useMutation(editDayMutation, {
    onSuccess: async () => {
      await onMutationSuccess();
      onCloseDialog();
    },
  });

  const formik = useFormik({
    initialValues: {
      isFrozen: false,
      overrideDayVal: '',
      overrideIncreaseVal: '',
      notes: '',
    },
    onSubmit: (values) => {
      mutate({
        ...values,
        overrideDayVal: !isNaN(parseInt(values.overrideDayVal))
          ? values.overrideDayVal
          : null,
        overrideIncreaseVal: !isNaN(parseInt(values.overrideIncreaseVal))
          ? values.overrideIncreaseVal
          : null,
        dayId: selectedDay.day.dayId,
      });
    },
  });

  useEffect(() => {
    if (selectedDay) {
      formik.setFieldValue('isFrozen', selectedDay.day.isFrozen);
      formik.setFieldValue(
        'overrideIncreaseVal',
        selectedDay.day.overrideIncreaseVal,
      );
      formik.setFieldValue('overrideDayVal', selectedDay.day.overrideDayVal);
      formik.setFieldValue('notes', selectedDay.day.notes);
    }
    /*eslint-disable-next-line*/
  }, [selectedDay]);

  return (
    <CustomDialog
      title="Detalles de este día"
      description=""
      open={!!selectedDay}
      onConfirmation={formik.handleSubmit}
      onClose={onCloseDialog}
      okButtonText="Editar"
      cancelButtonText="Cancelar"
    >
      <div>
        <h4>
          {selectedDay?.user?.firstName} {selectedDay?.user?.lastName} -{' '}
          {selectedDay?.day?.date}
        </h4>
        <p>Es dia habil: {selectedDay?.day?.isWorkday ? 'Si' : 'No'}</p>
        {/* <p>Acumula este día: ${selectedDay?.user?.accumAmount}</p> */}
        <p>Total acumulado hasta hoy: ${selectedDay?.day?.accum}</p>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <FormControlLabel
            label="Congelado"
            control={<Checkbox color="primary" />}
            checked={formik.values.isFrozen}
            onChange={(event) =>
              formik.setFieldValue('isFrozen', event.target.checked)
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            className={classes.Input}
            type="text"
            margin="dense"
            variant="outlined"
            name="overrideIncreaseVal"
            label="Sobreescribir lo que acumula este día"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.overrideIncreaseVal}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            className={classes.Input}
            InputLabelProps={{ className: classes.InputLabel }}
            InputProps={{
              classes: {
                notchedOutline: classes.InputOutline,
              },
            }}
            name="overrideDayVal"
            type="text"
            margin="dense"
            variant="outlined"
            label="Sobreescribir balance en este día"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.overrideDayVal}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            className={classes.Input}
            type="number"
            margin="dense"
            variant="outlined"
            name="notes"
            label="Notas"
            multiline
            rows={2}
            rowsMax={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.notes}
          />
        </GridItem>
      </GridContainer>
    </CustomDialog>
  );
};

export default MonthDetailForm;
