import {
  Work,
  Group,
  LocalAtm,
  DateRange,
  Dashboard as DashboardIcon,
  Description,
  Security,
  ListAlt,
} from '@material-ui/icons';

import Requests from '../containers/Requests/Requests';
import Calendars from '../containers/Calendars/Calendars';
import CalendarForm from '../containers/Calendars/CalendarForm/CalendarForm';
import Businesses from '../containers/Businesses/Businesses';
import BusinessForm from '../containers/Businesses/BusinessForm/BusinessForm';
import Users from '../containers/Users/Users';
import UserForm from '../containers/Users/UserForm/UserForm';
import CalendarDetail from '../containers/Calendars/CalendarDetail/CalendarDetail';
import MonthDetail from '../containers/MonthDetail/MonthDetail';
import Dashboard from '../containers/Dashboard/Dashboard';
import Administrators from '../containers/Administrators/Administrators';
import AdministratorForm from '../containers/Administrators/AdministratorForm/AdministratorForm';
import Audit from '../containers/Audit/Audit';
import Settings from '../containers/Settings/Settings';

const routes = [
  {
    onlySuperAdmin: true,
    path: '/',
    sidebarName: 'Dashboard',
    navbarName: 'Dashboard',
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    onlySuperAdmin: true,
    path: '/administrators/create',
    navbarName: 'Nuevo Administrador',
    component: AdministratorForm,
  },
  {
    onlySuperAdmin: true,
    path: '/administrators/:adminId/edit',
    navbarName: 'Editar Administrador',
    component: AdministratorForm,
  },
  {
    onlySuperAdmin: true,
    path: '/administrators',
    sidebarName: 'Administradores',
    navbarName: 'Administradores',
    component: Administrators,
    icon: Security,
  },
  {
    onlySuperAdmin: true,
    path: '/businesses/create',
    navbarName: 'Nueva Entidad',
    component: BusinessForm,
  },
  {
    path: '/businesses/:businessId/edit',
    navbarName: 'Editar Entidad',
    component: BusinessForm,
  },
  {
    path: '/businesses',
    sidebarName: 'Entidades',
    navbarName: 'Entidades',
    icon: Work,
    component: Businesses,
  },
  {
    path: '/users/create',
    navbarName: 'Nuevo Usuario',
    component: UserForm,
  },
  {
    path: '/users/:userId/edit',
    navbarName: 'Editar Usuario',
    component: UserForm,
  },
  {
    path: '/users',
    sidebarName: 'Usuarios',
    navbarName: 'Usuarios',
    icon: Group,
    component: Users,
  },
  {
    path: '/requests',
    sidebarName: 'Solicitudes',
    navbarName: 'Solicitudes',
    icon: LocalAtm,
    component: Requests,
  },
  {
    onlySuperAdmin: true,
    path: '/calendars/create',
    navbarName: 'Nuevo Calendario',
    component: CalendarForm,
  },
  {
    onlySuperAdmin: true,
    path: '/calendars/:calendarId/edit',
    navbarName: 'Editar Calendario',
    component: CalendarForm,
  },
  {
    onlySuperAdmin: true,
    path: '/calendars/:calendarId',
    navbarName: 'Detalle Calendario',
    component: CalendarDetail,
  },
  {
    onlySuperAdmin: true,
    path: '/calendars',
    sidebarName: 'Calendarios',
    navbarName: 'Calendarios',
    icon: DateRange,
    component: Calendars,
  },
  {
    onlySuperAdmin: true,
    path: '/month-detail',
    sidebarName: 'Detalle de un mes',
    navbarName: 'Detalle de un mes',
    icon: Description,
    component: MonthDetail,
  },
  {
    onlySuperAdmin: true,
    path: '/audit',
    sidebarName: 'Auditoría',
    navbarName: 'Auditoría',
    icon: ListAlt,
    component: Audit,
  },
  {
    path: '/admin/settings',
    navbarName: 'Configuración',
    component: Settings,
  },
];

export default routes;
