/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React from 'react';
// components
import Button from '../../UI/CustomButtons/Button';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
// material ui icons
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
// styles
import headerLinkStyle from '../../../assets/jss/components/headerLinkStyle';

import { useHistory } from 'react-router-dom';

const HeaderLinks = (props) => {
  const history = useHistory();
  const { classes } = props;
  return (
    <div>
      <Button
        color={window.innerWidth > 959 ? 'transparent' : 'white'}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Logout"
        className={classes.buttonLink}
        onClick={ props.onSettings }
      >
        <SettingsIcon className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Configuración</p>
        </Hidden>
      </Button> 
      <Button
        color={window.innerWidth > 959 ? 'transparent' : 'white'}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Logout"
        className={classes.buttonLink}
        onClick={props.onLogout}
      >
        <LogoutIcon className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Logout</p>
        </Hidden>
      </Button>
    </div>
  );
};

export default withStyles(headerLinkStyle)(HeaderLinks);
