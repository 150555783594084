import React, { Component } from 'react';
import { Route } from 'react-router-dom';
// components
import Auth from '../../../containers/Auth/Auth';
// styles
import classes from './FullScreenLayout.module.css';

class FullScreenLayout extends Component {
  render() {
    return (
      <div className={classes.Content}>
        <Route path="/" component={Auth} />
      </div>
    );
  }
}

export default FullScreenLayout;
