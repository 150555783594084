import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { TextField, Button } from '@material-ui/core';
import moment from 'moment';
import axios from '../axios';
// components
import Card from './UI/Card/Card';
import CardBody from './UI/Card/CardBody';
import GridContainer from './UI/Grid/GridContainer';
import GridItem from './UI/Grid/GridItem';
import Input from './UI/Input/Input';

const fetchBusinesses = () => {
  return axios.get('/businesses').then((res) => res.data.data);
};

const today = moment();

const MonthDetailSearch = ({ onSearch }) => {
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [month, setMonth] = useState(today.month() + 1);
  const [year, setYear] = useState(today.year());

  const { data, isLoading } = useQuery('businesses', fetchBusinesses, {
    onSuccess: (data) => setSelectedBusiness(data[0].businessId),
  });

  const businesses = data?.map((business) => {
    return {
      value: business.businessId,
      label: business.name,
    };
  });

  return (
    <Card style={{ width: '98%' }}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Input
              element={{
                label: 'Entidad',
                elementType: 'select',
                elementConfig: {
                  variant: 'outlined',
                  options: businesses || [],
                  disabled: isLoading,
                  mdUpSize: 6,
                },
                value: selectedBusiness,
              }}
              onChange={(value) => setSelectedBusiness(value)}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              style={{ width: '100%' }}
              type="number"
              margin="dense"
              variant="outlined"
              label="Mes"
              value={month}
              onChange={(event) => setMonth(event.target.value)}
              inputProps={{ min: 1, max: 12, step: 1 }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              style={{ width: '100%' }}
              type="number"
              margin="dense"
              variant="outlined"
              label="Año"
              value={year}
              onChange={(event) => setYear(event.target.value)}
            />
          </GridItem>
          <GridItem container alignItems="center" xs={12} sm={12} md={3}>
            <Button
              disabled={isLoading}
              color="primary"
              variant="contained"
              onClick={() => onSearch(selectedBusiness, month, year)}
            >
              Ver detalle
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default MonthDetailSearch;
