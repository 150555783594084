import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchCalendars, deleteCalendar, setToast } from '../../store/actions';
// components
import TableList from '../../components/UI/TableList/TableList';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
// material ui core components
import { withStyles } from '@material-ui/core/styles';
// styles
import listsContainerStyles from '../../assets/jss/containers/listsContainer';

const PAGE_LIMIT = 12;

const Calendars = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [calendarToDelete, setCalendarToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const getCalendars = async () => {
      setLoading(true);
      await dispatch(fetchCalendars());
      setLoading(false);
    };
    getCalendars();
  }, [dispatch]);

  const { calendars } = useSelector((state) => state.calendars);

  const pageClickHandler = ({ currentPage }) => {
    setCurrentPage(currentPage);
  };

  const createCalendarHandler = () => {
    history.push({
      pathname: '/calendars/create',
      state: {
        editMode: false,
      },
    });
  };

  const editCalendarHandler = (calendarId) => {
    const calendar = calendars.find((c) => c.calendarTemplateId === calendarId);
    history.push({
      pathname: '/calendars/' + calendarId + '/edit',
      state: {
        calendar,
        editMode: true,
      },
    });
  };

  const showCalendarDetailHandler = (calendarId) => {
    const calendar = calendars.find((c) => c.calendarTemplateId === calendarId);
    history.push({
      pathname: '/calendars/' + calendarId,
      state: {
        calendar,
      },
    });
  };

  const deleteCalendarHandler = async () => {
    try {
      await dispatch(deleteCalendar(calendarToDelete.calendarTemplateId));
      dispatch(setToast('El calendario fue eliminado con éxito'));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(setToast(error.response.data.showMessage?.ES, 'danger'));
      }
    }
    setCalendarToDelete(null);
    setShowDialog(false);
  };

  const openDialogHandler = (calendarId) => {
    const calendar = calendars.find((c) => c.calendarTemplateId === calendarId);
    setCalendarToDelete(calendar);
    setShowDialog(true);
  };

  const closeDialogHandler = () => {
    setCalendarToDelete(null);
    setShowDialog(false);
  };

  let calendarsList = null;
  if (loading) {
    calendarsList = <Spinner />;
  } else if (calendars.length > 0) {
    const currentPageCalendars = calendars.slice(
      (currentPage - 1) * PAGE_LIMIT,
      currentPage * PAGE_LIMIT,
    );

    const calendarsData = currentPageCalendars.map((calendar) => {
      return [
        calendar.calendarTemplateId,
        calendar.calendarTemplateId,
        calendar.name,
        calendar.descr,
      ];
    });

    calendarsList = (
      <TableList
        title="Calendarios"
        head={['Id', 'ID', 'Nombre', 'Descripción', '']}
        data={calendarsData}
        onShowDetail={showCalendarDetailHandler}
        detail
        onEdit={editCalendarHandler}
        onDelete={openDialogHandler}
        edit
        delete
        pagination={
          <Pagination
            totalRecords={calendars.length}
            pageLimit={PAGE_LIMIT}
            pageNeighbours={10}
            onPageChanged={pageClickHandler}
          />
        }
      />
    );
  }

  let dialogMessage = '';
  if (calendarToDelete) {
    dialogMessage = `¿Estás seguro que deseas eliminar el calendario ${calendarToDelete.name}?`;
  }

  return (
    <Fragment>
      {calendarsList}
      <AddButton onClick={createCalendarHandler} />
      <CustomDialog
        title="Confirmar"
        description={dialogMessage}
        open={showDialog}
        onConfirmation={deleteCalendarHandler}
        onClose={closeDialogHandler}
        okButtonText="Eliminar"
        cancelButtonText="Cancelar"
      />
    </Fragment>
  );
};

export default withStyles(listsContainerStyles)(Calendars);
