/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// components
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Table from '../Table/Table';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import SortableTable from '../Table/SortableTable';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
// styles
import listsContainer from '../../../assets/jss/containers/listsContainer';

function TableList(props) {
  const { classes } = props;
  return (
    <GridContainer>
      {props.filters ? <GridItem xs={12}>{props.filters}</GridItem> : null}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h4 className={classes.cardTitleWhite}>{props.title}</h4>
              {props.headerActions}
            </div>
          </CardHeader>
          <CardBody>
            {props.sortable ? (
              <SortableTable
                tableHeaderColor="primary"
                tableHead={props.head}
                tableData={props.data}
                accept={props.accept}
                reject={props.reject}
                detail={props.detail}
                edit={props.edit}
                delete={props.delete}
                onShowDetail={props.onShowDetail}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                onSortEnd={props.onSortEnd}
              />
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={props.head}
                tableData={props.data}
                accept={props.accept}
                reject={props.reject}
                detail={props.detail}
                edit={props.edit}
                delete={props.delete}
                onShowDetail={props.onShowDetail}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                selectable={props.selectable}
                selected={props.selected}
                onSelect={props.onSelect}
                clickable={props.clickable}
                onCellClick={props.onCellClick}
                style={props.style}
                highlightDeleted={props.highlightDeleted}
                onDisable={props.onDisable}
                disableable={props.disableable}
                setStatus={props.setStatus}
                onSetAsPaid={props.onSetAsPaid}
                onSetAsRejected={props.onSetAsRejected}
                onSuspend={props.onSuspend}
                suspend={props.suspend}
                rawData={props.rawData}
                rawKey={props.rawKey}
              />
            )}
            {props.pagination}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

TableList.propTypes = {
  title: PropTypes.string,
  head: PropTypes.array,
  data: PropTypes.array,
  accept: PropTypes.bool,
  reject: PropTypes.bool,
  detail: PropTypes.bool,
  sortable: PropTypes.bool,
  edit: PropTypes.bool,
  delete: PropTypes.bool,
  onShowDetail: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSortEnd: PropTypes.func,
  pagination: PropTypes.element,
  selectable: PropTypes.bool,
};

export default withRouter(withStyles(listsContainer)(TableList));
