/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React from 'react';
import classNames from 'classnames';
// material ui core components
import Grid from '@material-ui/core/Grid';
// styles
import classes from './Grid.module.css';

function GridContainer(props) {
  const { children, className, ...rest } = props;
  return (
    <Grid
      className={classNames(classes.GridContainer, className)}
      container
      {...rest}
    >
      {children}
    </Grid>
  );
}

export default GridContainer;
