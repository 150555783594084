import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { setToast } from '../../../store/actions';
import axios from '../../../axios';
import moment from 'moment';
import 'moment/locale/es';
// components
import GridItem from '../../../components/UI/Grid/GridItem';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import OutlinedSelect from '../../../components/UI/Input/OutlinedSelect/OutlinedSelect';
import CustomButton from '../../../components/UI/CustomButtons/Button';
import CustomDialog from '../../../components/UI/CustomDialog/CustomDialog';
// material ui components
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  CalendarToday as CalendarTodayIcon,
  Add as AddIcon,
} from '@material-ui/icons';
// styles
import classes from './CalendarDetail.module.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const generateEvents = (month, year) => {
  const events = [];
  const currentMonth = moment()
    .months(month ? month - 1 : moment().month())
    .years(year || moment().year());
  const daysCount = currentMonth.daysInMonth();
  for (let i = 1; i <= daysCount; i++) {
    const date = currentMonth.date(i).toDate();
    events.push({ start: date, end: date, title: 'Habil' });
  }
  return events;
};

const CalendarDetail = () => {
  const { state } = useLocation();
  const [calendars, setCalendars] = useState(state.calendar.months);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [events, setEvents] = useState(generateEvents());
  const [month, setMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());
  const { calendarId } = useParams();
  const dispatch = useDispatch();
  const [toDelete, setToDelete] = useState(null);

  const selectDateHandler = ({ slots, action }) => {
    if (action === 'click') {
      const date = moment(slots[0]).toISOString();
      const isDateSelected = selectedDates.includes(date);
      if (isDateSelected) {
        setSelectedDates((prevState) => prevState.filter((d) => d !== date));
      } else {
        setSelectedDates((prevState) => [...prevState, date]);
      }
    } else if (action === 'select') {
      setSelectedDates((prevState) => {
        const dates = slots.map((slot) => moment(slot).toISOString());
        return [...new Set([...prevState, ...dates])];
      });
    }
  };

  const customDayPropGetter = (date) => {
    if (selectedDates.includes(date.toISOString())) {
      return {
        className: '',
        style: {
          backgroundColor: 'lightsteelblue',
        },
      };
    }
    return {};
  };

  const customEventPropGetter = (event) => {
    const style = {};
    switch (event.title) {
      case 'Feriado':
        style.backgroundColor = 'blue';
        break;
      case 'Habil':
        style.backgroundColor = 'green';
        break;
      case 'No habil':
        style.backgroundColor = 'red';
        break;
      default:
        style.backgroundColor = 'gray';
        break;
    }
    return { className: '', style };
  };

  const markAsWorkdayHandler = () => {
    const newEvents = [...events];
    for (const date of selectedDates) {
      const index = newEvents.findIndex((event) => {
        const equalDates = event.start.getDate() === new Date(date).getDate();
        return equalDates && event.title === 'No habil';
      });
      if (index > -1) {
        newEvents[index].title = 'Habil';
      }
    }
    setEvents(newEvents);
    setSelectedDates([]);
  };

  const markAsNotWorkdayHandler = () => {
    const newEvents = [...events];
    for (const date of selectedDates) {
      const index = newEvents.findIndex((event) => {
        const equalDates = event.start.getDate() === new Date(date).getDate();
        return equalDates && event.title === 'Habil';
      });
      if (index > -1) {
        newEvents[index].title = 'No habil';
      }
    }
    setEvents(newEvents);
    setSelectedDates([]);
  };

  const markAsHolidayHandler = () => {
    const newEvents = selectedDates
      .filter((date) => {
        return !events.some((event) => {
          const equalDates = event.start.getDate() === new Date(date).getDate();
          return equalDates && event.title === 'Feriado';
        });
      })
      .map((date) => {
        return {
          start: new Date(date),
          end: new Date(date),
          title: 'Feriado',
        };
      });
    setEvents((prevEvents) => [...prevEvents, ...newEvents]);
    setSelectedDates([]);
  };

  const monthChangedHandler = (event) => {
    setMonth(event.target.value);
    setEvents(generateEvents(event.target.value, year));
    setSelectedDates([]);
  };

  const yearChangedHandler = (event) => {
    setYear(event.target.value);
    setEvents(generateEvents(month, event.target.value));
    setSelectedDates([]);
  };

  const addNewMonthHandler = () => {
    const lastCalendar = calendars[calendars.length - 1];
    const lastMonth = lastCalendar ? lastCalendar.month : moment().month() + 1;
    const lastYear = lastCalendar ? lastCalendar.year : moment().year();
    const date = moment().months(lastMonth).years(lastYear).add(1, 'month');
    setSelectedMonth(null);
    setEvents(generateEvents(date.month(), date.year()));
    setMonth(date.month());
    setYear(date.year());
    setSelectedDates([]);
  };

  const selectMonthHandler = (monthId) => {
    const month = calendars.find((c) => c.monthTemplateId === monthId);
    const monthEvents = [];
    for (const day of month.days) {
      const date = new Date(month.year, month.month - 1, day.day);
      monthEvents.push({
        start: date,
        end: date,
        title: day.isWorkday ? 'Habil' : 'No habil',
      });
      if (day.isHoliday) {
        monthEvents.push({
          start: date,
          end: date,
          title: 'Feriado',
        });
      }
    }
    setSelectedMonth(monthId);
    setEvents(monthEvents);
    setMonth(month.month);
    setSelectedDates([]);
    setYear(month.year);
  };

  const deleteMonthTemplateHandler = async () => {
    try {
      await axios.delete(
        `/calendar-templates/${calendarId}/months/${toDelete}`,
      );
      setCalendars((calendars) =>
        calendars.filter((month) => month.monthTemplateId !== toDelete),
      );
      setToDelete(null);
      dispatch(setToast('El calendario fue eliminado con éxito'));
    } catch (error) {
      if (error?.response?.data) {
        dispatch(setToast(error.response.data.showMessage?.ES, 'danger'));
      } else {
        dispatch(
          setToast('Error al intentar eliminar el calendario', 'danger'),
        );
      }
      setToDelete(null);
    }
  };

  const editTemplateHandler = async () => {
    const days = [];
    for (const event of events) {
      const day = event.start.getDate();
      if (days.some((d) => d.day === day)) {
        const index = days.findIndex((d) => d.day === day);
        if (event.title === 'Habil') {
          days[index].isWorkday = true;
        } else if (event.title === 'No habil') {
          days[index].isWorkday = false;
        } else if (event.title === 'Feriado') {
          days[index].isHoliday = true;
        }
      } else {
        let isWorkday = true;
        let isHoliday = false;
        if (event.title === 'Habil') {
          isWorkday = true;
        } else if (event.title === 'No habil') {
          isWorkday = false;
        } else if (event.title === 'Feriado') {
          isHoliday = true;
        }
        days.push({ day, isWorkday, isHoliday, notes: '' });
      }
    }

    const body = {
      calendarTemplateId: calendarId,
      monthTemplateId: selectedMonth,
      year: year,
      month: month,
      notes: '',
      days,
    };

    try {
      await axios.patch(
        `/calendar-templates/${calendarId}/months/${selectedMonth}`,
        body,
      );
      dispatch(setToast('El calendario del mes fue modificado con éxito'));
      setCalendars((oldCalendars) => {
        const newCalendars = [...oldCalendars];
        const calendar = oldCalendars.findIndex(
          (m) => m.monthTemplateId === selectedMonth,
        );
        newCalendars[calendar] = body;
        return newCalendars;
      });
    } catch (err) {
      dispatch(setToast('Error al modificar el calendario del mes', 'danger'));
    }
  };

  const createTemplateHandler = async () => {
    const lastCalendar = calendars[calendars.length - 1];
    if (lastCalendar && lastCalendar.month === month) {
      return dispatch(
        setToast('No puedes volver a crear este calendario', 'danger'),
      );
    }

    const days = [];
    for (const event of events) {
      const day = event.start.getDate();
      if (days.some((d) => d.day === day)) {
        const index = days.findIndex((d) => d.day === day);
        if (event.title === 'Habil') {
          days[index].isWorkday = true;
        } else if (event.title === 'No habil') {
          days[index].isWorkday = false;
        } else if (event.title === 'Feriado') {
          days[index].isHoliday = true;
        }
      } else {
        let isWorkday = true;
        let isHoliday = false;
        if (event.title === 'Habil') {
          isWorkday = true;
        } else if (event.title === 'No habil') {
          isWorkday = false;
        } else if (event.title === 'Feriado') {
          isHoliday = true;
        }
        days.push({ day, isWorkday, isHoliday, notes: '' });
      }
    }

    const body = {
      calendarTemplateId: calendarId,
      year: year,
      month: month,
      notes: '',
      days,
    };

    try {
      await axios.post(`/calendar-templates/${calendarId}/months`, body);
      dispatch(setToast('El calendario del mes fue creado con éxito'));
      setCalendars((oldCalendars) => [...oldCalendars, body]);
    } catch (err) {
      dispatch(setToast('Error al crear el calendario del mes', 'danger'));
    }
  };

  let calendarButtons = <div style={{ height: 37 }}></div>;
  if (selectedDates.length > 0) {
    calendarButtons = (
      <div className={classes.CalendarButtons}>
        <Button onClick={markAsWorkdayHandler}>Marcar como Hábil</Button>
        <Button onClick={markAsNotWorkdayHandler}>Marcar como No Hábil</Button>
        <Button onClick={markAsHolidayHandler}>Marcar como Feriado</Button>
      </div>
    );
  }

  calendars.sort((a, b) => a.month - b.month);
  const calendarDate = moment()
    .months(month - 1)
    .years(year)
    .startOf('month')
    .toDate();

  return (
    <>
      <Card profile>
        <CardBody profile>
          <h1>Detalle Calendario</h1>
          <h3>{state.calendar.name}</h3>
          <p>{state.calendar.descr}</p>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <List style={{ marginTop: 45 }}>
                {calendars.map((month) => {
                  const monthText = moment(month.month, 'MM').format('MMMM');
                  return (
                    <ListItem
                      button
                      key={month.monthTemplateId}
                      selected={selectedMonth === month.monthTemplateId}
                      onClick={() => selectMonthHandler(month.monthTemplateId)}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <CalendarTodayIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ textTransform: 'capitalize' }}
                        primary={`${monthText} ${month.year}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Delete"
                          onClick={() => setToDelete(month.monthTemplateId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button style={{ marginTop: 8 }} onClick={addNewMonthHandler}>
                  <AddIcon style={{ marginRight: 8 }} /> Agregar mes
                </Button>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <OutlinedSelect
                    name="month"
                    label="Mes"
                    className={classes.Input}
                    value={month}
                    onChange={monthChangedHandler}
                    options={[
                      { value: 1, label: 'Enero' },
                      { value: 2, label: 'Febrero' },
                      { value: 3, label: 'Marzo' },
                      { value: 4, label: 'Abril' },
                      { value: 5, label: 'Mayo' },
                      { value: 6, label: 'Junio' },
                      { value: 7, label: 'Julio' },
                      { value: 8, label: 'Agosto' },
                      { value: 9, label: 'Septiembre' },
                      { value: 10, label: 'Octubre' },
                      { value: 11, label: 'Noviembre' },
                      { value: 12, label: 'Diciembre' },
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <OutlinedSelect
                    name="year"
                    label="Año"
                    className={classes.Input}
                    value={year}
                    onChange={yearChangedHandler}
                    options={[
                      { value: 2020, label: '2020' },
                      { value: 2021, label: '2021' },
                      { value: 2022, label: '2022' },
                      { value: 2023, label: '2023' },
                      { value: 2024, label: '2024' },
                      { value: 2025, label: '2025' },
                    ]}
                  />
                </GridItem>
              </GridContainer>
              <div className={classes.CalendarContainer}>
                {calendarButtons}
                <div className={classes.Calendar}>
                  <Calendar
                    localizer={localizer}
                    date={calendarDate}
                    events={events}
                    toolbar={false}
                    views={['month']}
                    eventPropGetter={customEventPropGetter}
                    dayPropGetter={customDayPropGetter}
                    selectable
                    onSelectSlot={selectDateHandler}
                  />
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <CustomButton
            className={classes.Button}
            color="primary"
            onClick={
              selectedMonth ? editTemplateHandler : createTemplateHandler
            }
          >
            {selectedMonth ? 'MODIFICAR' : 'CONFIRMAR'} CALENDARIO
          </CustomButton>
        </CardFooter>
      </Card>
      <CustomDialog
        title="Confirmar"
        description="¿Estás seguro que deseas eliminar este calendario?"
        open={!!toDelete}
        onConfirmation={deleteMonthTemplateHandler}
        onClose={() => setToDelete(null)}
        okButtonText="Eliminar"
        cancelButtonText="Cancelar"
      />
    </>
  );
};

export default CalendarDetail;
