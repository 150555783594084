import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
// components
import CustomDialog from '../UI/CustomDialog/CustomDialog';
import OutlinedSelect from '../UI/Input/OutlinedSelect/OutlinedSelect';
// material ui components
import { TextField } from '@material-ui/core';

const ModifyAccumValuesForm = (props) => {
  const formik = useFormik({
    initialValues: {
      accumType: 'FIXED',
      accumValue: '',
    },
    validationSchema: yup.object().shape({
      accumValue: yup
        .number()
        .required('Requerido')
        .min(0, 'El valor mínimo es 0')
        .when('accumType', {
          is: (value) => value === 'PERCENTAGE',
          then: yup
            .number()
            .required('Requerido')
            .min(0, 'El porcentaje mínimo es 0')
            .max(100, 'El porcentaje máximo es 100'),
        }),
    }),
    onSubmit: (values) => {
      props.onSubmit(values);
    },
  });

  const hasError = (name) => !!formik.errors[name] && formik.touched[name];

  return (
    <CustomDialog
      title="Modificar Tope Mensual"
      description=""
      open={props.open}
      onConfirmation={formik.handleSubmit}
      onClose={props.onCloseDialog}
      okButtonText="Modificar"
      cancelButtonText="Cancelar"
    >
      <form onSubmit={formik.handleSubmit}>
        <OutlinedSelect
          name="accumType"
          style={{ width: '100%' }}
          label="Tipo de Acumulación Máxima"
          value={formik.values.accumType}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          options={[
            { value: 'PERCENTAGE', label: 'Valor porcentual' },
            { value: 'FIXED', label: 'Valor fijo' },
          ]}
        />
        <TextField
          style={{ width: '100%' }}
          name="accumValue"
          type="number"
          margin="dense"
          variant="outlined"
          label="Acumulación máxima mensual"
          error={hasError('accumValue')}
          helperText={hasError('accumValue') && formik.errors.accumValue}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.accumValue}
          required
        />
      </form>
    </CustomDialog>
  );
};

export default ModifyAccumValuesForm;
