import React from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createCalendar, setToast, editCalendar } from '../../../store/actions';
// components
import GridItem from '../../../components/UI/Grid/GridItem';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import CardHeader from '../../../components/UI/Card/CardHeader';
import Button from '../../../components/UI/CustomButtons/Button';
// material ui components
import { TextField } from '@material-ui/core';
// styles
import classes from './CalendarForm.module.css';

const CalendarForm = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { calendarId } = params;
  const { editMode, calendar } = location.state;

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      name: editMode ? calendar.name : '',
      descr: editMode ? calendar.descr : '',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Requerido'),
      descr: yup.string().required('Requerido'),
    }),
    onSubmit: async (values) => {
      try {
        if (editMode) {
          await dispatch(editCalendar(calendarId, values));
          dispatch(setToast('El calendario fue editado con éxito'));
        } else {
          await dispatch(createCalendar(values));
          dispatch(setToast('El calendario fue creado con éxito'));
        }
        history.push('/calendars');
      } catch (err) {
        if (editMode) {
          dispatch(
            setToast('Se produjo un error al editar el calendario', 'danger'),
          );
        } else {
          dispatch(
            setToast('Se produjo un error al crear el calendario', 'danger'),
          );
        }
      }
    },
  });

  const hasError = (name) => !!formik.errors[name] && formik.touched[name];

  return (
    <Card profile>
      <CardHeader>
        <h1 className={classes.Title}>
          {editMode ? 'Editar Calendario' : 'Crear Calendario'}
        </h1>
      </CardHeader>
      <CardBody profile>
        <form onSubmit={formik.handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                className={classes.Input}
                name="name"
                type="text"
                margin="dense"
                variant="outlined"
                label="Nombre"
                error={hasError('name')}
                helperText={hasError('name') && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                multiline
                rows={2}
                rowsMax={4}
                className={classes.Input}
                name="descr"
                type="text"
                margin="dense"
                variant="outlined"
                label="Descripción"
                error={hasError('descr')}
                helperText={hasError('descr') && formik.errors.descr}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.descr}
                required
              />
            </GridItem>
          </GridContainer>
        </form>
      </CardBody>
      <CardFooter>
        <Button
          className={classes.Button}
          color="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid}
        >
          {editMode ? 'ACTUALIZAR CALENDARIO' : 'CREAR CALENDARIO'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default CalendarForm;
