import * as actionTypes from '../actions/actionTypes';

const initialState = {
  businesses: [],
  error: null,
  loading: false,
};

const businessesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BUSINESSES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_BUSINESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        businesses: action.businesses,
      };
    case actionTypes.FETCH_BUSINESSES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.CREATE_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: [...state.businesses, action.business],
      };
    case actionTypes.EDIT_BUSINESS_SUCCESS:
      const businessIndex = state.businesses.findIndex((b) => {
        return b.businessId === action.business.businessId;
      });
      const updatedBusinesses = [...state.businesses];
      updatedBusinesses[businessIndex] = action.business;
      return {
        ...state,
        businesses: updatedBusinesses,
      };
    case actionTypes.DELETE_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: state.businesses.filter((b) => {
          return b.businessId !== action.businessId;
        }),
      };
    default:
      return state;
  }
};

export default businessesReducer;
