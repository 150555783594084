import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { updateObject } from '../../shared/utility';
import { checkValidity } from '../../shared/validation';
// components
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
// material ui core components
import { Button, Card } from '@material-ui/core';
// styles
import classes from './Auth.module.css';

class Auth extends Component {
  state = {
    controls: {
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Email',
        },
        value: '',
        validation: {
          required: true,
          isEmail: true,
        },
        valid: false,
        touched: false,
      },
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Contraseña',
        },
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
    },
    formIsValid: false,
  };

  // send to previous route or predefined
  componentDidMount() {
    if (this.props.isLoggedIn) {
      const { from } = this.props.location.state || {
        from: { path: '/' },
      };
      this.props.history.push(from);
    }
  }

  // send to predefined route if user tries to login again
  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoggedIn) {
      this.props.history.push('/');
    }
  }

  inputChangedHandler = (value, controlName) => {
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: value,
        valid: checkValidity(
          value,
          this.state.controls[controlName].validation,
        ),
        touched: true,
      }),
    });
    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }
    this.setState({ controls: updatedControls, formIsValid: formIsValid });
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.props.onAuth(
      this.state.controls.email.value,
      this.state.controls.password.value,
    );
  };

  render() {
    let form = Object.keys(this.state.controls).map((key) => {
      let authElement = this.state.controls[key];
      return (
        <div className={classes.InputContainer} key={key}>
          <Input
            element={authElement}
            onChange={(value) => this.inputChangedHandler(value, key)}
          />
        </div>
      );
    });

    if (this.props.loading) {
      form = <Spinner />;
    }

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p className={classes.ErrorMessage}>{this.props.error}</p>;
    }

    return (
      <div className={classes.Auth}>
        <div className={classes.AuthFormContainer}>
          <div className={classes.AuthFormSubContainer}>
            <img
              className={classes.Logo}
              src={require('../../assets/images/sueldoya-blanco-v2.png')}
              alt="sueldoYa"
            />
            <Card className={classes.AuthForm}>
              <form onSubmit={this.submitHandler} noValidate autoComplete="off">
                {form}
                {!this.props.loading && (
                  <Button
                    className={classes.SubmitButton}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!this.state.formIsValid}
                  >
                    Entrar
                  </Button>
                )}
                {errorMessage}
              </form>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isLoggedIn: state.auth.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => dispatch(actions.login(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
