/*!

 =========================================================
 * Material Dashboard React - v1.5.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import React from 'react';
import PropTypes from 'prop-types';
// material ui core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Checkbox } from '@material-ui/core';
// material ui icons
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Description from '@material-ui/icons/Description';
import Done from '@material-ui/icons/Done';
import Block from '@material-ui/icons/Block';
// styles
import tableStyle from '../../../assets/jss/components/tableStyle';

const CustomTable = ({
  classes,
  tableHead,
  tableData,
  tableHeaderColor,
  selectable,
  selected,
  onSelect,
  rawKey,
  rawData,
  ...props
}) => {
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((prop) => prop[0]);
      onSelect(newSelecteds);
      return;
    }
    onSelect([]);
  };

  const handleSelectClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    onSelect(newSelected);
  };

  const isSelected = (id) => selectable && selected.indexOf(id) !== -1;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table} style={props.style}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow>
              {selectable ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < tableData.length
                    }
                    checked={
                      tableData.length > 0 &&
                      selected.length === tableData.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              ) : null}
              {tableHead.map((prop, key) => {
                return prop !== 'Id' ? (
                  <TableCell
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                ) : null;
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            const isItemSelected = isSelected(prop[0]);
            const rawRow = rawData?.find((x) => x[rawKey] === prop[0]);
            return (
              <TableRow
                key={key}
                hover={selectable}
                role={selectable && 'checkbox'}
                selected={selectable && isItemSelected}
                aria-checked={selectable && isItemSelected}
                onClick={
                  selectable && ((event) => handleSelectClick(event, prop[0]))
                }
                style={{
                  backgroundColor:
                    props.highlightDeleted &&
                    prop[prop.length - 1] === 'Eliminado' &&
                    'rgba(0,0,0,0.3)',
                }}
              >
                {selectable ? (
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                ) : null}
                {prop.map((data, key) => {
                  const isClickable = props.clickable && key > 2;
                  return key !== 0 ? (
                    <TableCell
                      className={classes.tableCell}
                      onClick={
                        isClickable
                          ? () => props.onCellClick(prop[0], key)
                          : undefined
                      }
                      style={{
                        cursor: isClickable ? 'pointer' : 'auto',
                        color:
                          typeof data === 'string' &&
                          data.includes('*') &&
                          '#1470af',
                      }}
                      key={key}
                    >
                      {typeof data === 'string' ? data.replace('*', '') : data}
                    </TableCell>
                  ) : null;
                })}
                {props.detail ||
                props.edit ||
                props.delete ||
                props.setStatus ? (
                  <TableCell className={classes.tableActions}>
                    {props.detail ? (
                      <Tooltip
                        id="tooltip-top"
                        title="Detalle"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Detalle"
                          className={classes.tableActionButton}
                          onClick={props.onShowDetail.bind(this, prop[0])}
                        >
                          <Description
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.edit ? (
                      <Tooltip
                        id="tooltip-top"
                        title="Editar"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Editar"
                          className={classes.tableActionButton}
                          onClick={props.onEdit.bind(this, prop[0])}
                        >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.delete ? (
                      <Tooltip
                        id="tooltip-top-start"
                        title="Eliminar"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Eliminar"
                          className={classes.tableActionButton}
                          onClick={props.onDelete.bind(this, prop[0])}
                        >
                          <Close
                            className={
                              classes.tableActionButtonIcon +
                              ' ' +
                              classes.close
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.suspend ? (
                      <Tooltip
                        id="tooltip-top-start"
                        title={
                          rawRow.suspendedAt ? 'Dessuspender' : 'Suspender'
                        }
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label={
                            rawRow.suspendedAt ? 'Dessuspender' : 'Suspender'
                          }
                          className={classes.tableActionButton}
                          onClick={props.onSuspend.bind(this, prop[0])}
                        >
                          {rawRow.suspendedAt ? (
                            <Done
                              className={
                                classes.tableActionButtonIcon +
                                ' ' +
                                classes.close
                              }
                            />
                          ) : (
                            <Block
                              className={
                                classes.tableActionButtonIcon +
                                ' ' +
                                classes.close
                              }
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.disableable ? (
                      prop[prop.length - 1] === 'Activo' ? (
                        <Tooltip
                          id="tooltip-top-start"
                          title="Eliminar"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Eliminar"
                            className={classes.tableActionButton}
                            onClick={props.onDisable.bind(this, prop[0])}
                          >
                            <Close
                              className={
                                classes.tableActionButtonIcon +
                                ' ' +
                                classes.close
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          id="tooltip-top-start"
                          title="Activar"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton
                            aria-label="Activar"
                            className={classes.tableActionButton}
                            onClick={props.onDisable.bind(this, prop[0])}
                          >
                            <Done
                              className={
                                classes.tableActionButtonIcon +
                                ' ' +
                                classes.done
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      )
                    ) : null}
                    {props.setStatus &&
                    (prop[prop.length - 1] === 'Pendiente' ||
                      prop[prop.length - 1] === 'Rechazada') ? (
                      <Tooltip
                        id="tooltip-top-start"
                        title="Marcar como pagada"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Marcar como pagada"
                          className={classes.tableActionButton}
                          onClick={props.onSetAsPaid.bind(this, prop[0])}
                        >
                          <Done
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.done
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.setStatus &&
                    (prop[prop.length - 1] === 'Pendiente' ||
                      prop[prop.length - 1] === 'Aceptada') ? (
                      <Tooltip
                        id="tooltip-top-start"
                        title="Marcar como rechazada"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Marcar como rechazada"
                          className={classes.tableActionButton}
                          onClick={props.onSetAsRejected.bind(this, prop[0])}
                        >
                          <Close
                            className={
                              classes.tableActionButtonIcon +
                              ' ' +
                              classes.close
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </TableCell>
                ) : null}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
  accept: PropTypes.bool,
  reject: PropTypes.bool,
  detail: PropTypes.bool,
  edit: PropTypes.bool,
  delete: PropTypes.bool,
  onShowDetail: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default withStyles(tableStyle)(CustomTable);
