import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// material ui core components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// styles
import classes from './OutlinedSelect.module.css';

class OutlinedSelect extends Component {
  state = {
    labelWidth: 0,
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  render() {
    let optionMenuItems = this.props.options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });

    return (
      <FormControl
        variant="outlined"
        margin="dense"
        className={classes.FormControl}
        disabled={this.props.disabled}
      >
        <InputLabel
          ref={(ref) => {
            this.InputLabelRef = ref;
          }}
          htmlFor="outlined-age-simple"
        >
          {this.props.label}
        </InputLabel>
        <Select
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          input={
            <OutlinedInput
              labelWidth={this.state.labelWidth}
              name="age"
              id="outlined-age-simple"
            />
          }
        >
          {optionMenuItems}
        </Select>
      </FormControl>
    );
  }
}

OutlinedSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.node,
    }),
  ),
  onChange: PropTypes.func,
};

export default OutlinedSelect;
