// auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const ADMIN_UPDATE_SUCCESSFUL = 'ADMIN_UPDATE_SUCCESSFUL';

// businesses
export const FETCH_BUSINESSES_START = 'FETCH_BUSINESSES_START';
export const FETCH_BUSINESSES_SUCCESS = 'FETCH_BUSINESSES_SUCCESS';
export const FETCH_BUSINESSES_FAIL = 'FETCH_BUSINESSES_FAIL';
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS';
export const EDIT_BUSINESS_SUCCESS = 'EDIT_BUSINESS_SUCCESS';
export const DELETE_BUSINESS_SUCCESS = 'DELETE_BUSINESS_SUCCESS';

// users
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const SUSPEND_USER_SUCCESS = 'SUSPEND_USER_SUCCESS';
export const UPDATE_USER_PROPS = 'UPDATE_USER_PROPS';

// toast
export const SET_TOAST = 'SET_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

// calendars
export const FETCH_CALENDARS = 'FETCH_CALENDARS';
export const CREATE_CALENDAR = 'CREATE_CALENDAR';
export const EDIT_CALENDAR = 'EDIT_CALENDAR';
export const DELETE_CALENDAR = 'DELETE_CALENDAR';
