import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
// components
import CustomDialog from '../UI/CustomDialog/CustomDialog';
// material ui components
import { TextField } from '@material-ui/core';

const ModifyRateValuesForm = (props) => {
  const formik = useFormik({
    initialValues: {
      rateAmount: 0,
      ratePercentage: 0,
    },
    validationSchema: yup.object().shape(
      {
        rateAmount: yup
          .number()
          .min(0, 'El valor mínimo es 0')
          .when('ratePercentage', {
            is: (value) => +value > 0,
            then: yup
              .number()
              .min(0, 'El valor mínimo es 0')
              .max(0, 'Al menos uno de los dos atributos debe ser 0'),
          }),
        ratePercentage: yup
          .number()
          .min(0, 'El valor mínimo es 0')
          .when('rateAmount', {
            is: (value) => +value > 0,
            then: yup
              .number()
              .min(0, 'El valor mínimo es 0')
              .max(0, 'Al menos uno de los dos atributos debe ser 0'),
          }),
      },
      [['rateAmount', 'ratePercentage']],
    ),
    onSubmit: (values) => {
      props.onSubmit(values);
    },
  });

  const hasError = (name) => !!formik.errors[name] && formik.touched[name];

  return (
    <CustomDialog
      title="Modificar Tarifa"
      description=""
      open={props.open}
      onConfirmation={formik.handleSubmit}
      onClose={props.onCloseDialog}
      okButtonText="Modificar"
      cancelButtonText="Cancelar"
    >
      <form onSubmit={formik.handleSubmit}>
        <TextField
          style={{ width: '100%' }}
          name="rateAmount"
          type="number"
          margin="dense"
          variant="outlined"
          label="Monto Tarifa"
          error={hasError('rateAmount')}
          helperText={hasError('rateAmount') && formik.errors.rateAmount}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.rateAmount}
        />
        <TextField
          style={{ width: '100%' }}
          name="ratePercentage"
          type="number"
          margin="dense"
          variant="outlined"
          label="Porcentaje Tarifa"
          error={hasError('ratePercentage')}
          helperText={
            hasError('ratePercentage') && formik.errors.ratePercentage
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.ratePercentage}
        />
      </form>
    </CustomDialog>
  );
};

export default ModifyRateValuesForm;
