import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchUsers,
  deleteUser,
  uploadUsers,
  setToast,
  modifyAccumValue,
  modifyRateValues,
  modifyDailyIncrementValue,
  suspendUser,
} from '../../store/actions';
import * as AWS from '../../aws';
import axios from '../../axios';
// import axiosMock from '../../axios-mock';
// components
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import TableList from '../../components/UI/TableList/TableList';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';
import Input from '../../components/UI/Input/Input';
import ModifyAccumValuesForm from '../../components/Users/ModifyAccumValuesForm';
import ModifyDailyIncrementValuesForm from '../../components/Users/ModifyDailyIncrementValuesForm';
import ModifyRateValuesForm from '../../components/Users/ModifyRateValuesForm';
// material ui core components
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CloudUpload, Send, Edit, KeyboardArrowDown } from '@material-ui/icons';
// styles
import listsContainerStyles from '../../assets/jss/containers/listsContainer';
import classes from './Users.module.css';

const PAGE_LIMIT = 12;

const USER_STATUS = {
  NEW: 'Nuevo',
  PENDING: 'Pendiente',
  ACCEPTED: 'Invitación Aceptada',
  REGISTERED: 'Registrado',
};

const Users = () => {
  /* Table state */
  const [selectedBusiness, setSelectedBusiness] = useState('Todas');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [businesses, setBusinesses] = useState([]);
  const [selected, setSelected] = useState([]);
  /* Deletion dialog state */
  const [showDeletionDialog, setShowDeletionDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  /* Suspend dialog state */
  const [showSuspendDialog, setShowSuspendDialog] = useState(false);
  const [userToSuspend, setUserToSuspend] = useState(null);
  /* Massive import state */
  const [selectedImportBusiness, setSelectedImportBusiness] = useState('');
  const [selectedImportFile, setSelectedImportFile] = useState(null);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formToShow, setFormToShow] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('Todos');
  const [loadedPic, setLoadedPic] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const { data } = await axios.get('/businesses');
        const options = data.data.map((business) => {
          return {
            value: business.businessId,
            label: business.name,
          };
        });
        setBusinesses(options);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBusinesses();
  }, []);

  useEffect(() => {
    const fetchPic = async () => {
      if (userDetail?.RUTPic) {
        const data = await AWS.getObject(userDetail.RUTPic.slice(1));
        setLoadedPic(data);
      }
    };
    fetchPic();
  }, [userDetail]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  useEffect(() => {
    users.sort((a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    });
    setFilteredUsers(users);
  }, [users]);

  const pageClickHandler = ({ currentPage }) => {
    setCurrentPage(currentPage);
  };

  const createUserHandler = () => {
    history.push({
      pathname: '/users/create',
      state: {
        editMode: false,
      },
    });
  };

  const editUserHandler = (userId) => {
    const user = users.find((u) => u.userId === userId);
    history.push({
      pathname: '/users/' + userId + '/edit',
      state: {
        editMode: true,
        user,
      },
    });
  };

  const deleteUserHandler = () => {
    dispatch(deleteUser(userToDelete.userId));
    setUserToDelete(null);
    setShowDeletionDialog(false);
    setSelected([]);
  };

  const openDeleteDialogHandler = (userId) => {
    const user = users.find((u) => u.userId === userId);
    setUserToDelete(user);
    setShowDeletionDialog(true);
  };

  const closeDeleteDialogHandler = () => {
    setUserToDelete(null);
    setShowDeletionDialog(false);
  };

  const suspendUserHandler = () => {
    if (userToSuspend.suspendedAt) {
      dispatch(suspendUser(userToSuspend.userId, false));
    } else {
      dispatch(suspendUser(userToSuspend.userId));
    }
    setUserToSuspend(null);
    setShowSuspendDialog(false);
    setSelected([]);
  };

  const openSuspendDialogHandler = (userId) => {
    const user = users.find((u) => u.userId === userId);
    setUserToSuspend(user);
    setShowSuspendDialog(true);
  };

  const closedSuspendDialogHandler = () => {
    setUserToSuspend(null);
    setShowSuspendDialog(false);
  };

  const selectBusinessHandler = (value) => {
    setSelectedBusiness(value);
  };

  const searchUserHandler = (value) => {
    setSearchText(value);
  };

  const selectImportFileHandler = (files) => {
    setSelectedImportFile(files[0]);
  };

  const openImportDialogHandler = () => {
    setShowImportDialog(true);
  };

  const selectImportBusinessHandler = (value) => {
    setSelectedImportBusiness(value);
  };

  const usersImportHandler = async () => {
    await dispatch(uploadUsers(selectedImportBusiness, selectedImportFile));
    await dispatch(setToast('Los usuarios fueron importados con éxito'));
    setShowImportDialog(false);
  };

  const closeImportDialogHandler = () => {
    setSelectedImportBusiness('');
    setSelectedImportFile(null);
    setShowImportDialog(false);
  };

  const selectItemsHandler = (value) => {
    setSelected(value);
  };

  const openInviteDialogHandler = () => {
    setShowInviteDialog(true);
  };

  const inviteUsersHandler = async () => {
    await axios.post('/users/send-invitations', selected);
    await dispatch(setToast('Las invitaciones fueron enviadas con éxito'));
    setSelected([]);
    setShowInviteDialog(false);
  };

  const closeInviteDialogHandler = () => {
    setShowInviteDialog(false);
  };

  const closeFormHandler = () => {
    setFormToShow(null);
  };

  const accumFormSubmitHandler = async (values) => {
    await dispatch(
      modifyAccumValue(selected, values.accumType, values.accumValue),
    );
    await dispatch(setToast('El tope mensual fue modificado con éxito'));
    setSelected([]);
    setFormToShow(null);
  };

  const dailyIncrementFormSubmitHandler = async (values) => {
    await dispatch(modifyDailyIncrementValue(selected, values.dailyIncrement));
    await dispatch(setToast('El incremento diario fue modificado con éxito'));
    setSelected([]);
    setFormToShow(null);
  };

  const rateFormSubmitHadler = async (values) => {
    await dispatch(
      modifyRateValues(selected, values.rateAmount, values.ratePercentage),
    );
    await dispatch(setToast('La tarifa fue modificada con éxito'));
    setSelected([]);
    setFormToShow(null);
  };

  const showUserDetailHandler = (userId) => {
    const user = users.find((user) => user.userId === userId);
    setShowDetail(true);
    setUserDetail(user);
  };

  let usersList = null;
  if (users.length > 0) {
    let usersToShow = filteredUsers;

    if (searchText) {
      usersToShow = usersToShow.filter((user) => {
        return ['firstName', 'lastName', 'email', 'RUT'].some((key) => {
          return user[key].toLowerCase().includes(searchText.toLowerCase());
        });
      });
    }

    if (selectedBusiness !== 'Todas') {
      usersToShow = usersToShow.filter((user) => {
        return user.business.businessId === selectedBusiness;
      });
    }

    if (selectedStatus !== 'Todos') {
      usersToShow = usersToShow.filter((user) => {
        return user.status === selectedStatus;
      });
    }

    const currentPageUsers = usersToShow
      .filter((user) => user)
      .slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT);

    const usersData = currentPageUsers.map((user) => {
      return [
        user.userId,
        user.firstName,
        user.lastName,
        user.RUT,
        user.email,
        user.business
          ? user.business.name
          : businesses.find((b) => b.businessId === user.businessId),
        USER_STATUS[user.status],
      ];
    });

    const validSelections = selected.length > 0;

    let pagination = null;
    if (usersToShow.length > 0) {
      pagination = (
        <Pagination
          totalRecords={usersToShow.length}
          pageLimit={PAGE_LIMIT}
          pageNeighbours={10}
          onPageChanged={pageClickHandler}
        />
      );
    }

    const filters = (
      <GridContainer className={classes.Search}>
        <GridItem xs={12} sm={12} md={3}>
          <Input
            element={{
              label: 'Buscar..',
              elementType: 'input',
              elementConfig: {
                type: 'text',
                placeholder: 'Buscar..',
              },
              value: searchText,
              validation: {},
            }}
            onChange={searchUserHandler}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <Input
            element={{
              label: 'Entidad',
              elementType: 'select',
              elementConfig: {
                variant: 'outlined',
                options: [{ value: 'Todas', label: 'Todas' }, ...businesses],
                mdUpSize: 6,
              },
              value: selectedBusiness,
            }}
            onChange={selectBusinessHandler}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <Input
            element={{
              label: 'Estado',
              elementType: 'select',
              elementConfig: {
                variant: 'outlined',
                options: [
                  { value: 'Todos', label: 'Todos' },
                  { value: 'NEW', label: 'Nuevo' },
                  { value: 'PENDING', label: 'Pendiente' },
                  { value: 'ACCEPTED', label: 'Aceptado' },
                  { value: 'REGISTERED', label: 'Registrado' },
                ],
                mdUpSize: 6,
              },
              value: selectedStatus,
            }}
            onChange={(value) => setSelectedStatus(value)}
          />
        </GridItem>
        {validSelections && (
          <React.Fragment>
            <GridItem
              container
              alignItems="center"
              justify="center"
              xs={12}
              sm={6}
              md={3}
            >
              <Button
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                Modificar Seleccionados{' '}
                <KeyboardArrowDown style={{ marginLeft: '0.5rem' }} />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setFormToShow('accum');
                  }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText inset primary="Modificar Tope Mensual" />
                </MenuItem>
                {user?.isSuperAdmin && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setFormToShow('rate');
                    }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    <ListItemText inset primary="Modificar Tarifa" />
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setFormToShow('dailyIncrement');
                  }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText inset primary="Modificar Incremento Diario" />
                </MenuItem>
              </Menu>
            </GridItem>
            <GridItem
              container
              alignItems="center"
              justify="center"
              xs={12}
              sm={6}
              md={2}
            >
              <Button onClick={openInviteDialogHandler}>
                <Send className={classes.ButtonIcon} /> ENVIAR INVITACIONES
              </Button>
            </GridItem>
          </React.Fragment>
        )}
      </GridContainer>
    );

    const headerActions = user?.isSuperAdmin ? (
      <Button onClick={openImportDialogHandler} className={classes.Button}>
        <CloudUpload className={classes.ButtonIcon} />{' '}
        <span>Importar masivamente</span>
      </Button>
    ) : null;

    usersList = (
      <TableList
        title="Usuarios"
        head={[
          'Id',
          'Nombre',
          'Apellido',
          'RUT',
          'Email',
          'Entidad',
          'Estado',
          '',
        ]}
        data={usersData}
        rawKey="userId"
        rawData={currentPageUsers}
        onEdit={editUserHandler}
        edit
        onDelete={openDeleteDialogHandler}
        delete={user?.isSuperAdmin}
        onSuspend={openSuspendDialogHandler}
        suspend
        detail
        onShowDetail={showUserDetailHandler}
        selectable
        selected={selected}
        onSelect={selectItemsHandler}
        pagination={pagination}
        filters={filters}
        headerActions={headerActions}
      />
    );
  } else if (loading) {
    usersList = <Spinner />;
  }

  let deletionMessage = '';
  if (userToDelete) {
    deletionMessage = `¿Estás seguro que deseas eliminar el usuario ${userToDelete.firstName} ${userToDelete.lastName}?`;
  }

  let suspendMessage = '';
  let suspendAction = '';
  if (userToSuspend) {
    suspendAction = userToSuspend.suspendedAt ? 'dessuspender' : 'suspender';
    suspendMessage = `¿Estás seguro que deseas ${suspendAction} al usuario ${userToSuspend.firstName} ${userToSuspend.lastName}?`;
  }

  let inviteMessage = '¿Estás seguro que deseas enviar invitación a 1 usuario?';
  if (selected.length > 1) {
    inviteMessage = `¿Estás seguro que deseas enviar invitaciones a ${selected.length} usuarios?`;
  }

  return (
    <Fragment>
      {usersList}
      <AddButton onClick={createUserHandler} />
      <CustomDialog
        title="Confirmar"
        description={deletionMessage}
        open={showDeletionDialog}
        onConfirmation={deleteUserHandler}
        onClose={closeDeleteDialogHandler}
        okButtonText="Eliminar"
        cancelButtonText="Cancelar"
      />
      <CustomDialog
        title="Confirmar"
        description={suspendMessage}
        open={showSuspendDialog}
        onConfirmation={suspendUserHandler}
        onClose={closedSuspendDialogHandler}
        okButtonText={suspendAction}
        cancelButtonText="Cancelar"
      />
      <CustomDialog
        title="Enviar invitaciones"
        description={inviteMessage}
        open={showInviteDialog}
        onConfirmation={inviteUsersHandler}
        onClose={closeInviteDialogHandler}
        okButtonText="Enviar"
        cancelButtonText="Cancelar"
      />
      <CustomDialog
        title="Importar masivamente"
        description=""
        open={showImportDialog}
        onConfirmation={usersImportHandler}
        onClose={closeImportDialogHandler}
        okButtonText="Importar"
        cancelButtonText="Cancelar"
      >
        <Input
          element={{
            label: 'Entidad',
            elementType: 'select',
            elementConfig: {
              variant: 'outlined',
              options: businesses,
              mdUpSize: 6,
            },
            value: selectedImportBusiness,
          }}
          onChange={selectImportBusinessHandler}
        />
        <Input
          element={{
            label: 'Archivo de Excel',
            elementType: 'input',
            elementConfig: {
              type: 'file',
              accept: '.csv',
              placeholder: 'Archivo de Excel',
              shrink: true,
            },
            validation: {},
          }}
          onChange={selectImportFileHandler}
        />
      </CustomDialog>
      <ModifyRateValuesForm
        open={formToShow === 'rate'}
        onSubmit={rateFormSubmitHadler}
        onCloseDialog={closeFormHandler}
      />
      <ModifyAccumValuesForm
        open={formToShow === 'accum'}
        onSubmit={accumFormSubmitHandler}
        onCloseDialog={closeFormHandler}
      />
      <ModifyDailyIncrementValuesForm
        open={formToShow === 'dailyIncrement'}
        onSubmit={dailyIncrementFormSubmitHandler}
        onCloseDialog={closeFormHandler}
      />
      <CustomDialog
        title="Detalle del usuario"
        description=""
        open={showDetail}
        onClose={() => {
          setShowDetail(false);
          setLoadedPic(null);
        }}
        cancelButtonText="Cerrar"
      >
        {userDetail && (
          <div className={classes.UserDetail}>
            <h3>
              {userDetail.firstName} {userDetail.lastName}
            </h3>
            <p>{userDetail.RUT}</p>
            {userDetail.RUTPic ? (
              loadedPic ? (
                <img
                  src={loadedPic}
                  alt={`RUT de ${userDetail.firstName} ${userDetail.lastName}`}
                />
              ) : (
                <Spinner />
              )
            ) : (
              <p>Usuario no ha subido foto de su documento</p>
            )}
          </div>
        )}
      </CustomDialog>
    </Fragment>
  );
};

export default withStyles(listsContainerStyles)(Users);
